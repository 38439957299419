import './register.css';
import { useState, useRef } from 'react';
import http from '../../http/index';
import { message } from 'antd';
import { useNavigate } from 'react-router';
function Register(props) {
    const phoneRef = useRef();
    const codeRef = useRef();
    const passwordRef = useRef();
    const prePasswordRef = useRef();
    const [countText, setCountText] = useState('获取验证码');
    let [get_code, set_get_code] = useState(false);
    let navigate = useNavigate();
    // 登录账号只能输入数字
    function changeEvent(e) {
        let value = e.target.value.replace(/[^\d]/g, '');
        phoneRef.current.value = value;
    }
    // 验证码 or 密码 or 确认密码 赋值
    function passwordFn(e, type) {
        let value = e.target.value;
        if (type == 1) {
            codeRef.current.value = value.replace(/[^\d]/g, '');
        } else if (type == 2) {
            passwordRef.current.value = value.replace(/[^\w\.\/]/ig,'');
        } else if (type == 2) {
            prePasswordRef.current.value = value.replace(/[^\w\.\/]/ig,'');
        }
    }
    // 发送验证码
    function getCode() {
        if (!get_code) {
            set_get_code(true);
            if (phoneRef.current.value.length != 11) {
                message.error('请输入正确的手机号');
                set_get_code(false);
                return
            }
            let params = {
                phone: phoneRef.current.value,
            };
            http.getCode(params).then(res => {
                if (res.code == 200) {
                    let count = 60;
                    let myInterval = setInterval(() => {
                        let text = '';
                        if (count == 1) {
                            text = '发送验证码';
                            set_get_code(false);
                            clearInterval(myInterval);
                        } else {
                            count--;
                            text = count + 's';
                        }
                        setCountText(text);
                    }, 1000);
                } else {
                    set_get_code(false);
                    message.error(res.msg);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    // 注册账号
    function registerAction() {
        let params = {
            user_phone: phoneRef.current.value,
            code: codeRef.current.value,
            password: passwordRef.current.value,
            repeat_password: prePasswordRef.current.value
        };
        if (params.user_phone.length != 11) {
            message.error('请输入正确的手机号');
            return
        }
        if (params.code == '') {
            message.error('请输入验证码');
            return
        }
        if (params.password == '') {
            message.error('请输入设置密码');
            return
        }
        let reg = /^(\d+[a-zA-Z]+|[a-zA-Z]+\d+)([0-9a-zA-Z]*)$/;
        if (params.password.length < 6 || params.password.length > 20 || !params.password.match(reg)) {
            message.error('您设置的密码不符合格式，请重新设置');
            return
        }
        if (params.repeat_password == '') {
            message.error('请输入重复确认密码');
            return
        }
        if (params.repeat_password.length < 6 || params.repeat_password.length > 20 || !params.repeat_password.match(reg)) {
            message.error('您设置的重复确认密码不符合格式，请重新设置');
            return
        }
        if (params.password != params.repeat_password) {
            message.error('两次输入密码不相同，请重新设置');
            return
        }
        http.register(params).then((res) => {
            if (res.code == 200) {
                localStorage.setItem('admin_id', res.data.user_id);
                navigate('/registerMerchant');
            } else {
                message.error(res.msg);
            }
        });
    }
    return <div className='box'>
        <div className='box_header'>红丝婚礼商家中心</div>
        <div className='register_main'>
            <div className='box_main_model'>
                <div className='model_title'>
                    <div className='title_items title_items_active'>
                        <div className='title_items_num'>1</div>
                        <div className='title_items_text'>注册账号</div>
                    </div>
                    <div className='title_items_bg'></div>
                    <div className='title_items'>
                        <div className='title_items_num'>2</div>
                        <div className='title_items_text'>注册商户</div>
                    </div>
                    <div className='title_items_bg'></div>
                    <div className='title_items'>
                        <div className='title_items_num'>3</div>
                        <div className='title_items_text'>提交资质</div>
                    </div>
                </div>
                <div className='model_content'>
                    <span><span>*</span>您的手机号</span>
                    <input ref={phoneRef} type='text' placeholder='账户使用者手机号' className='model_content_inp' maxLength="11" onChange={(e) => changeEvent(e)} />
                </div>
                <div className='model_content'>
                    <span><span>*</span>手机号验证码</span>
                    <input ref={codeRef} type='text' placeholder='验证码' className='model_content_inp model_content_inp1' onChange={(e) => passwordFn(e, 1)} />
                    <div className={['get_code', countText == '发送验证码' ? null : 'get_code_active'].join(' ')} onClick={getCode}>{countText}</div>
                </div>
                <div className='model_content'>
                    <span><span>*</span>设置密码</span>
                    <input ref={passwordRef} type='password' placeholder='6-20位，必须包含数字和字母' maxLength={20} className='model_content_inp' onChange={(e) => passwordFn(e, 2)} />
                </div>
                <div className='model_content'>
                    <span><span>*</span>重复确认密码</span>
                    <input ref={prePasswordRef} type='password' placeholder='6-20位，必须包含数字和字母' maxLength={20} className='model_content_inp' onChange={(e) => passwordFn(e, 3)} />
                </div>
                <div className='register_btn' onClick={registerAction}>下一步</div>
            </div>
            <div className='box_main_bottom'> 沪公网安备 3101040200692号 | 沪ICP备17007425号</div>
        </div>
    </div>
}
export default Register;

