import {Checkbox,Popconfirm} from 'antd';
import { useEffect, useState } from 'react';
import { Progress } from 'antd';
import request from '../../http/';
import api from '../../http/api';

function PhotoItem(props){
  let {status,file,url,name,moreStatus,selectPhoto=[],id,onChange,onRemove,onLoad} = props;
  let [fileURL,setFileURL] = useState();// 图片地址
  let [fileStatus,setFileStatus] = useState(true);// 文件上传状态
  let [uploadPersent,setUploadPersent] = useState(0);
  let [prevStyle,setPrevStyle] = useState({bg:{},main:{}});// 图片展示样式
  let [checked,setChecked] = useState(false);
  let [photoId,setPhotoId] = useState();// 当前图片ID
  // 初始化
  useEffect(async ()=>{
    if(status === 0){
      // 当前文件未上传 ->读取文件
      let {target:{result}} = await fileRead(file);
      setFileURL(result);
      setFileStatus(false);
      uploadFile()
    }else{
      // 文件回填
      setPhotoId(id);
      setFileURL(api.imgUrl + url);
    }
  },[photoId,selectPhoto]);
  useEffect(()=>{
    if(!moreStatus)return;
    setChecked(selectPhoto.includes(photoId))
  },[selectPhoto,photoId])
  // 监听文件上传成功
  useEffect(()=>{
    if(uploadPersent < 100)return;
    setFileStatus(true)
  },[uploadPersent])
  function uploadFile(){
    let fromData = new FormData();
    fromData.append('file',file);
    request.uploadImg(fromData,{
      onUploadProgress: progressEvent => {
        let persent = (progressEvent.loaded / progressEvent.total * 100 | 0);
        persent = persent > 98 ? 98 : persent;
        setUploadPersent(persent)
      }
    })
    .then(({code,data})=>{
      let {data:url} = data;
      addToLibray(url);
    })
  };
  // 添加图片到素材库
  function addToLibray(url){
    request.addPhotoLibray({
      mer_id:675,
      name,
      url,
      type:1
    })
    .then(({msg,...rest})=>{
      setUploadPersent(100);
      onLoad()
    })
  };
  function fileRead(file){
    return new Promise((resolve,reject)=>{
      var reader = new FileReader();
      reader.onload = resolve;
      reader.readAsDataURL(file);
    })
  };
  // 点击图片项
  function clickHandler(){
    // 开启多选操作 向上push当前ID
    if(moreStatus){
      onChange(photoId)
    }
  };
  
  return <div className="photo_item">
    {
      moreStatus ? <Checkbox onChange={clickHandler} checked={checked} /> : <Popconfirm
      placement="topRight"
      title="确认删除此图片素材吗？"
      onConfirm={()=>{
        onRemove([photoId])
      }}
      okText="确认"
      cancelText="取消"
    >
      <div className="remove_icon">
        <img src={require('../../static/icon/libray_close.png')} />
      </div>
    </Popconfirm>
    }
    <div className="item_mask" onClick={clickHandler}></div>
    <div className="photo_preview">
      <img src={fileURL} className="prev_main" style={prevStyle.main} onLoad={({target})=>{
        let {width,height} = target;
        if(width > height) {
          setPrevStyle({
            bg:{width:'auto',height:'100%'},
            main:{width:'100%',height:'auto'}
          })
        }else{
          setPrevStyle({
            bg:{height:'auto',width:'100%'},
            main:{height:'100%',width:'auto'}
          })
        }
      }} />
      <img src={fileURL} className="prev_bg" style={prevStyle.bg} />
      {
        fileStatus ? '' : (<div className="uploadInfo">
        <Progress type="circle" percent={uploadPersent} trailColor="#fff" />
        </div>)
      }
    </div>
    <div className="photo_name">{name}</div>
  </div>
};
export default PhotoItem;