import './tableStyle.css';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form } from 'antd';
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  slot,
  rowIndex,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave(rowIndex,dataIndex,values)
      toggleEdit();
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required:  false,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} style={{width:'100%'}} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="edit_cell "
        style={{
          paddingRight: 24,
        }}
      >
        <div className="cell_value_holder" onClick={toggleEdit}>{children}</div>
        {slot}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    let {columns,onChange,dataSource} = props
    this.state = {
      dataSource,
      columns
    };
    this.pagination = props.pagination || false;
    if(onChange){
      this.onChange= onChange;
    };
  }
  onChange(){
    console.log('r');
  }
  handleSave(index,key,value){
    this.onChange(index,key,value);
  }
  render() {
    const { dataSource,columns } = this.props;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const renderColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record,rowIndex) => {
          return {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.onChange,
            slot:col.slot,
            rowIndex
          }
        },
      };
    });
    return <Table
      components={components}
      rowClassName={() => 'editable-row'}
      bordered
      dataSource={dataSource}
      columns={renderColumns}
      pagination={this.pagination}
      rowKey={(row,value,index)=>{
        return value
      }}
    />;
  }
};
export default EditableTable;