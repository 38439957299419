import './index.css';
import InfoBlock from './infoBlock';
import BasicInfo from './basicInfo';
import SaleAttr from './saleAttr';
import OrderAttr from './orderAttr';
import PicDesc from './picDesc';
import Logistic from './logistics';
import AfterSale from './afterSale';
import SaleInfo from './saleInfo';
import api from '../../../http/index';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import postConfig from './dataMap.json';
import checkPostData from './submitHandle';
import { useLocation, useNavigate } from 'react-router';
import qs from 'qs';
import { message } from '../../../utils/tool';
const PageConfig = [
  {
    title:'基础信息',
    component:BasicInfo
  },
  {
    title:'销售属性',
    component:SaleAttr
  },
  {
    title:'订单属性',
    component:OrderAttr

  },
  {
    title:'图文描述',
    component:PicDesc
  },
  {
    title:'物流信息',
    component:Logistic
  },
  {
    title:'售后服务',
    component:AfterSale
  },
  {
    title:'上架信息',
    component:SaleInfo
  }
];
var changeFn,postDataSource;
var changeDataQueue = [],changeDataTimer = null;
function changeData(value){
  if(changeDataTimer != null){
    clearTimeout(changeDataTimer);
  };
  changeDataQueue.push(value);
  changeDataTimer = setTimeout(() => {
    let nextData;
    let targetData = {};
    while(nextData = changeDataQueue.shift()){
      Object.assign(targetData,postDataSource,nextData)
    };
    changeFn(targetData);
  }, 300);
}
function GoodsDetail(props){
  let params = useLocation();
  let navigate = useNavigate();
  let {id} = qs.parse(params.search.slice(1));
  let [isCreate,setCreateState] = useState(true);
  // 修改数据栈
  const [goodsDetail,setGoodsDetail] = useState({})
  let classList = useSelector(state=>state.classIfyList);
  let [postData,setPostData] = useState({});
  let [emptyType,setEmptyType] = useState(undefined);// 空数据类型
  let dispatch = useDispatch();
  changeFn = setPostData;
  postDataSource = postData;
  if(typeof id !== 'undefined'){
    if(isCreate){
      setCreateState(false)
      getGoodsDetail();
    }
  };
  useEffect(()=>{
    // 检查分类是否被加载
    if(!classList.init && !classList.list.lengtn){
      api.getShopClassList({
        id:100,
        attr_type:'GOODS'
      })
      .then(res=>{
        let classLists = res.data.lists;
        let list = Object.keys(classLists).map((key)=>{
          let {
            attr_is_fill,
            attr_name,
            attr_id,
            attr_input_mode,
            list_value
          } = classLists[key];
          let selectList;
          if(attr_input_mode == 3 || attr_input_mode == 4){
            selectList = list_value.map(({list_value,list_id})=>{
              return {
                label:list_value,
                value:list_id
              }
            })
          };
          return {
            attr_is_fill,
            attr_name,
            attr_id,
            attr_input_mode,
            selectList
          }
        })
        dispatch({
          type:'SET_CLASS_LIST',
          data:list
        })
      })
    }
    
  },[]);
  // 修改数据发送到服务器
  function sendDataToService(data){
    // goods_area_id -> 区域
    // goods_city_id -> 城市
    // goods_classify -> 商家分类
    // goods_mer_id -> 商家ID
    let sendFn = isCreate ? api.createGood : api.editorGoods;
    let postData = Object.assign({
      goods_area_id:310101,
      goods_city_id:"310100",
      goods_classify:100,
      goods_mer_id:675
    },data);
    if(!isCreate){
      postData.id = id
    };
    sendFn(postData)
    .then(({code,msg})=>{
      if(code !== 200)return;
      message({
        type:'success',
        message:msg
      })
      setTimeout(() => {
        navigate(-1)
      }, 1000);
    })
  };
  function getGoodsDetail(){
    api.getGoodsDetail({id})
    .then(res=>{
      let data = res.data;
      let {attr_list,goods_attr_id} = data;
      goods_attr_id = goods_attr_id.split(',');
      // 回填数据至表单
      let goodsDetail = {};
      Object.keys(postConfig).forEach(key=>{
        goodsDetail[key] = data[key]
      });
      setGoodsDetail(goodsDetail);
    })
    .catch(err=>{
      
    });
  };
  return  <div className="good_detail">
    <div className="detail_title">{isCreate ?'新增商品' : '编辑商品'}</div>
    {
      PageConfig.map(item=>{
        return <InfoBlock
          title={item.title}
          key={item.title}
        >
          <item.component emptyType={emptyType} isCreate={isCreate} info={goodsDetail} onChange={changeData} />
        </InfoBlock>
      })
    }
    <div className="submit">
      <div className="btn_fixed">
        <div className="submit_btn_item fail" onClick={()=>{
          navigate(-1);
        }}>取消</div>
        <div className="submit_btn_item ok" onClick={()=>{
          let checked = checkPostData(postData,setEmptyType,classList);
          if(checked){
            sendDataToService(checked)
          }
        }}>提交</div>
      </div>
    </div>
  </div>
};
export default GoodsDetail;