import http from '../http/index';
function classIfyList(data={
  init:false,
  list:[],
  case:[],
  caseGroup:[]
},action){
  switch (action.type) {
    case 'SET_CLASS_LIST':
      data.list = action.data;
      data.init = true;
      break;
    case 'SET_CASE_CLASS':
      data.case = action.data;
      data.init = true;
      break;
    case 'SET_CASE_GROUP':
      data.caseGroup = action.data
      break;
  }
  return data;
};
export default classIfyList