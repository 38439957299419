import {createStore,combineReducers} from 'redux';
import slideConfig from './slideConfig';
import user from './user';
import classIfyList from './classIfy';
import merchantInfo from './merchantInfo';
var store = createStore(combineReducers({
  slideConfig,
  user,
  classIfyList,
  merchantInfo
}))
export default store;