import './index.css';
import InfoBlock from './infoBlock';
import BasicInfo from './basicInfo';
import PicDesc from './picDesc';
import SaleInfo from './saleInfo';
import api from '../../../http/index';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import checkPostData from './submitHandle';
import { useLocation, useNavigate } from 'react-router';
import qs from 'qs';
import { message } from '../../../utils/tool';
const PageConfig = [
  {
    title:'基础信息',
    component:BasicInfo
  },
  {
    title:'图文描述',
    component:PicDesc
  },
  {
    title:'上架信息',
    component:SaleInfo
  }
];
var changeFn,postDataSource;
var changeDataQueue = [],changeDataTimer = null;
function changeData(value){
  if(changeDataTimer != null){
    clearTimeout(changeDataTimer);
  };
  changeDataQueue.push(value);
  changeDataTimer = setTimeout(() => {
    let nextData;
    let targetData = {};
    while(nextData = changeDataQueue.shift()){
      Object.assign(targetData,postDataSource,nextData)
    };
    changeFn(targetData);
  }, 300);
}
function GoodsDetail(props){
  let params = useLocation();
  let navigate = useNavigate();
  let {id} = qs.parse(params.search.slice(1));
  let [isCreate,setCreateState] = useState(true);
  // 修改数据栈
  const [goodsDetail,setGoodsDetail] = useState({})
  let classList = useSelector(state=>state.classIfyList);
  let groupList = useSelector(state=>state.classIfyList.caseGroup);
  let [postData,setPostData] = useState({});
  let [emptyType,setEmptyType] = useState(undefined);// 空数据类型
  let dispatch = useDispatch();
  changeFn = setPostData;
  postDataSource = postData;
  if(typeof id !== 'undefined'){
    if(isCreate){
      setCreateState(false)
      getGoodsDetail();
    }
  };
  useEffect(()=>{
    // 加载分类数据
    console.log('加载前置数据');
    api.getShopClassList({
      id:100,
      attr_type:'CASUS'
    })
    .then(res=>{
      let classLists = res.data.lists;
      let list = Object.keys(classLists).map((key)=>{
        let {
          attr_is_fill,
          attr_name,
          attr_id,
          attr_input_mode,
          list_value
        } = classLists[key];
        let selectList;
        if(attr_input_mode == 3 || attr_input_mode == 4){
          selectList = list_value.map(({list_value,list_id})=>{
            return {
              label:list_value,
              value:list_id
            }
          })
        };
        return {
          attr_is_fill,
          attr_name,
          attr_id,
          attr_input_mode,
          selectList
        }
      });
      dispatch({
        type:'SET_CASE_CLASS',
        data:list
      })
    });
    // 加载案例分组数据
    api.getCaseGroup({mer_id: 675})
    .then(({data=[]})=>{
      dispatch({
        type:'SET_CASE_GROUP',
        data
      })
    })
    
  },[]);
  // 修改数据发送到服务器
  function sendDataToService(data){
    // casus_area_id -> 区域
    // casus_city_id -> 城市
    // casus_classify -> 商家分类
    // casus_mer_id -> 商家ID
    let sendFn = isCreate ? api.createCase : api.editorCase;
    let postData = Object.assign({
      casus_area_id:310101,
      casus_city_id:"310100",
      casus_classify:100,
      casus_mer_id:675
    },data);
    if(!isCreate){
      postData.id = id
    };
    sendFn(postData)
    .then(({code,msg})=>{
      if(code !== 200)return;
      message({
        type:'success',
        message:msg
      })
      setTimeout(() => {
        navigate(-1)
      }, 1000);
    })
  };
  function getGoodsDetail(){
    api.getCaseDetail({id})
    .then(res=>{
      let data = res.data;
      console.log('案例详情',data);
      setGoodsDetail(data);
    })
    .catch(err=>{
      
    });
  };
  return  <div className="case_detail">
    <div className="detail_title">{isCreate ?'新增案例' : '编辑案例'}</div>
    {
      PageConfig.map(item=>{
        return <InfoBlock
          title={item.title}
          key={item.title}
        >
          <item.component emptyType={emptyType} isCreate={isCreate} info={goodsDetail} onChange={changeData} />
        </InfoBlock>
      })
    }
    <div className="submit">
      <div className="btn_fixed">
        <div className="submit_btn_item fail" onClick={()=>{
          navigate(-1);
        }}>取消</div>
        <div className="submit_btn_item ok" onClick={()=>{
          let checked = checkPostData(postData,setEmptyType,classList,groupList);
          if(checked){
            sendDataToService(checked)
          }
        }}>提交</div>
      </div>
    </div>
  </div>
};
export default GoodsDetail;