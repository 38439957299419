
import { useEffect, useRef, useState } from 'react';
import SlideItem from './slide';
function SubMenus(props){
  let {children,open} = props;
  let [menuHeight,setMenuHeight] = useState(0);
  let [menuSty,setMenuSty] = useState({})
  useEffect(()=>{
    if(open){
      setMenuSty({height:menuHeight})
    }else{
      setMenuSty({})
    }
  },[open])
  function setHeight(height){
    if(menuHeight)return;
    setMenuHeight(height * children.length);
  };
  if(!Array.isArray(children))return '';
  if(!children.length)return;
  return <ul className="sub_slides" style={menuSty}>
    {
      children.map(item=><SlideItem onHeight={setHeight} key={item.title} {...item}/>)
    }
  </ul>
};
export default SubMenus;