import axios from 'axios';
// const BaseUrl = '/';    //生产
const myAxios = axios.create({
  timeout:20000
});
// 响应拦截器
myAxios.interceptors.response.use((response)=>{
  let {data,status} = response;
  // if(status != 200 || data.code != 200){
  //   return Promise.reject(data.msg);
  // };
  return response.data;
});
// 请求拦截器
myAxios.interceptors.request.use((config)=>{
  let token = localStorage.getItem('token') || sessionStorage.getItem('token')
  if(token){
    config.headers.token = token;
  }
  return config;
});
function get(url,params){
  return myAxios.get(url,{params})
};
function post(url,params,options){
  return myAxios.post(url,params,options)
}
//导出表格数据
function exportExcel(url, data = {}, contentType) {
  return new Promise((resolve, reject) => {
    myAxios.post(url, data, {
        headers: {
          'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded', //请求的数据类型为form data格式
        },
        'responseType': 'blob' //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
      })
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
  })
}
//上传
function QiniuUpload(url, data = {}) {
  return new Promise((resolve, reject) => {
    myAxios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
  })
}
export default myAxios;
export {get,post,exportExcel,QiniuUpload}