import { useEffect, useState } from 'react';
import { ImgUpload,TextEditor } from '../../../components';
function PicDesc(props){
  let {info,onChange,emptyType,isCreate} = props;
  let [headImg,setHeadImg] = useState([]);// 头图
  let [loopImg,setLoopImg] = useState([]);// 轮播图
  let [descption,setDescption] = useState('');// 图文介绍
  useEffect(()=>{
    if(isCreate)return;
    let {casus_drawing,casus_show_img,casus_details=''} = info;
    setHeadImg([casus_drawing]);
    setLoopImg([casus_show_img]);
    setDescption(casus_details);
  },[info.casus_drawing]);
  // 数据变化向上通知
  useEffect(()=>{
    let newValue = {
      casus_drawing:headImg.join(''),
      casus_show_img:loopImg.join(''),
      casus_details:descption
    };
    onChange(newValue);
  },[headImg,loopImg,descption])
  return <div className="input_list">
  <div className="input_item">
    <div className="input_item_title must_item">上传主图</div>
    <div className={`upload_value ${emptyType === 'casus_drawing' ? 'error' : ''}`}>
      <p className="upload_info_text">展示在商家详情页的案例封面，最佳尺寸为670*670像素，比例为1:1。<span className="form_blue_color">（仅支持上传一张）</span></p>
      <ImgUpload value={headImg} limit={1} onChange={(e)=>{
        setHeadImg(e);
      }} />
      <span className="error_info">请上传封面图片</span>
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">上传轮播图</div>
    <div className={`upload_value ${emptyType === 'casus_show_img' ? 'error' : ''}`}> 
      <p className="upload_info_text">展示在案例详情页的顶部横幅处，最佳尺寸为1080*810像素，比例为1.33:1。<span className="form_blue_color">（仅支持上传一张）</span></p>
      <ImgUpload value={loopImg} limit={1} onChange={(e)=>{
        setLoopImg(e)
      }}/>
      <span className="error_info">请上传横幅图片</span>
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title" style={{marginTop:'16px'}}>图文详情</div>
    <TextEditor value={descption} onChange={(e)=>{
      setDescption(e)
    }} />
  </div>
</div>
};
export default PicDesc;