import Quill from "quill"
import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
import { useEffect, useRef, useState } from "react";
import api from '../../http/api';
import './editor.css';
Quill.register('modules/ImageExtend', ImageExtend)
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [
      {
          header: 1
      },
      {
          header: 2
      }
  ], // custom button values
  [
      {
          list: 'ordered'
      },
      {
          list: 'bullet'
      }
  ],
  [
      {
          script: 'sub'
      },
      {
          script: 'super'
      }
  ], // superscript/subscript
  [
      {
          indent: '-1'
      },
      {
          indent: '+1'
      }
  ], // outdent/indent
  [
      {
          direction: 'rtl'
      }
  ], // text direction

  [
      {
        size:['small', false, 'large', 'huge']
      }
  ], // custom dropdown
  [
      {
          header: [1, 2, 3, 4, 5, 6, false]
      }
  ],

  [
      {
          color: []
      },
      {
          background: []
      }
  ], // dropdown with defaults from theme
  [
      {
          font: []
      }
  ],
  [
      {
          align: []
      }
  ],
  ['link', 'image'],
  ['clean'] // remove formatting button
];
function TextEditor(props){
  let {value} = props;
  var editorBox = useRef();
  var initState = useRef(false);
  let shakeTime = useRef(null);
  let [quill,setQuill] = useState(null);
  let [initData,setIninitData] = useState(false)
  let {onChange} = props;
  useEffect(()=>{
    if(initState.current) return;
    initState.current = true;
    let quill = new Quill(editorBox.current,{
      placeholder:'请输入内容',
      theme: 'snow', // or 'bubble'
      modules:{
        ImageExtend:{// 拖拽 复制 上传图片配置
          loading: true,
          name: 'file',
          action: api.uploadImg,
          response: (res) => {
            return api.imgUrl + res.data.data;
          }
        },
        toolbar:{
          container:toolbarOptions,
          handlers: {
            'image':function(value){
              if(value){
                QuillWatch.emit(quill.id)
              }
            }
          }
        }
      }
    });
    quill.on('text-change',(...rest)=>{
      if(shakeTime.current != null){
        clearTimeout(shakeTime.current);
      }
      shakeTime.current = setTimeout(() => {
        let html = editorBox.current.children[0].innerHTML;
        onChange(html)
      }, 400);
    });
    setQuill(quill)
  },[])
  useEffect(()=>{
    if(quill && !initData){
      quill.pasteHTML(value);
      setIninitData(true)
    }
  },[value])
  return <div className="text_editor">
    <div className="text_editor_ctrl"></div>
    <div className="text_editor_container" ref={editorBox}></div>
  </div>
};
export default TextEditor