import {DatePicker,Radio} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
const {RangePicker} = DatePicker;
const putShelfConfig =[
  {label:'立即上架',value:'1'},
  {label:'定时上架',value:'2'},
  {label:'放入仓库',value:'3'}
];
function SaleInfo (props){
  let {info,onChange,emptyType,isCreate} = props;
  let [putShelf,setPutShelf] = useState('1');// 上架配置选项
  let [putShelfTime,setPutShelfTime] = useState([]);// 定时上架时间
  useEffect(()=>{
    if(isCreate)return;
    let {goods_deploy,goods_status_timing,goods_end_timing} = info;
    if(goods_deploy === '2'){
      setPutShelfTime([
        moment(goods_status_timing),
        moment(goods_end_timing)
      ])
    }
    setPutShelf(goods_deploy)
  },[info.goods_deploy]);
  useEffect(()=>{
    let newValue = {
      goods_deploy:putShelf,
      goods_status_timing:putShelfTime[0],
      goods_end_timing:putShelfTime[1]
    };
    onChange(newValue);
  },[putShelf,putShelfTime])
  return <div className="input_list">
  <div className="input_item">
    <div className="input_item_title">上架配置</div>
    <div>
      <Radio.Group options={putShelfConfig} value={putShelf} onChange={e=>{
        setPutShelf(e.target.value)
      }} />
      {
        putShelf !== '2' ? '' :(<div className="timer_select">
        <div className="timer_title">定时上架时间</div>
        <div className={`${emptyType === 'shelfTimer' ? 'error' : ''}`}>
          <RangePicker 
            renges={{
              Today:[moment(),moment()],
              'This Month':[moment().startOf('month'),moment().endOf('month')]
            }}
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value={putShelfTime}
            placeholder={['请选择起始时间','请选择结束时间']}
            onChange={setPutShelfTime}
          ></RangePicker>
          <span className="error_info">上架时间不可为空</span>
        </div>
      </div>)
      }
      
    </div>
  </div>
</div>
};
export default SaleInfo;