import React from "react";
import reactDom from "react-dom";
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from "antd";
import moment from "moment";
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
reactDom.render(
  <BrowserRouter>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root')
)