import './submitQualification.css';
import ImgUpload from '../../components/imgUpload';
import title_checked from '../../static/icon/title_checked.png';
import business_license_icon from '../../static/imgs/business_license_icon.png';
import identity_card_icon from '../../static/imgs/identity_card_icon.png';
import identity_card_icon2 from '../../static/imgs/identity_card_icon2.png';
import identity_card_icon3 from '../../static/imgs/identity_card_icon3.png';
import identity_card_icon4 from '../../static/imgs/identity_card_icon4.png';
import { useState, useEffect, useRef } from 'react';
import http from '../../http/index';
import { message, Select, Radio, Input, Checkbox } from 'antd';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'
const { Option } = Select;
function SubmitQualification(props) {
    const optionType = [
        {
            id: 1,
            value: '企业法人营业执照'
        },
        {
            id: 2,
            value: '个人工商营业执照'
        },
    ]; // 营业执照类型
    const optionType1 = [
        {
            id: 1,
            value: '居民身份证'
        },
        {
            id: 2,
            value: '护照'
        },
        {
            id: 3,
            value: '港澳通行证'
        },
        {
            id: 4,
            value: '台湾通行证'
        },
    ]; // 法人身份证明类型
    const [action, setAction] = useState(false);// 是否提交信息进入下一步
    const [cancel, setCancel] = useState(false);// 上一步
    const dispatch = useDispatch();
    const [form, setForm] = useState(useSelector(state => state.merchantInfo));// 注册的商户信息
    let navigate = useNavigate();
    // 初始化数据
    useEffect(() => {
        if (form.mer_license[0].license_url != '') {
            form.mer_license_list = [form.mer_license[0].license_url];
        }
        if (form.mer_legal_person[0].type == 1) {
            if (form.mer_legal_person[0].info[0]) {
                form.mer_legal_person_list = [form.mer_legal_person[0].info[0]];
            }
            if (form.mer_legal_person[0].info[1]) {
                form.mer_legal_person_list1 = [form.mer_legal_person[0].info[1]];
            }
        } else if (form.mer_legal_person[0].type == 2) {
            form.mer_legal_person_list2 = form.mer_legal_person[0].info;
        } else if (form.mer_legal_person[0].type == 3) {
            form.mer_legal_person_list3 = form.mer_legal_person[0].info;
        } else if (form.mer_legal_person[0].type == 4) {
            form.mer_legal_person_list4 = form.mer_legal_person[0].info;
        }
        setForm(form);
    }, []);
    // 监听提交
    useEffect(() => {
        // 下一步
        if (action) {
            let params = form;
            setAction(false);
            if (!params.mer_license[0].license_url || params.mer_license[0].license_url == '') {
                message.error('营业执照不能为空');
                return
            }
            if (!params.mer_legal_person[0].info || params.mer_legal_person[0].info.length == 0) {
                message.error('法人身份证明不能为空');
                return
            }
            http.merchant_register(params).then((res) => {
                if (res.code == 200) {
                    navigate('/main');
                } else {
                    message.error(res.msg);
                }
            });
        }
    }, [action]);
    // 切换营业执照类型
    function optionChange(value) {
        let data = { ...form };
        data.mer_license[0].type = value;
        setForm(data);
    }
    // 切换法人身份证明类型
    function optionChange1(value) {
        let data = { ...form };
        data.mer_legal_person[0].type = value;
        setForm(data);
    }
    // 上传图片成功
    function successImage(imageList, type, type1, index) {
        let data = { ...form };
        if (type == 'business_license') {
            data.mer_license[0].license_url = imageList[0];
            form.mer_license_list = [form.mer_license[0].license_url];
        } else if (type == 'identity_card') {
            if (type1 == 1) {
                data.mer_legal_person[0].info[index] = imageList[0];
                if (form.mer_legal_person[0].info[0]) {
                    form.mer_legal_person_list = [form.mer_legal_person[0].info[0]];
                }
                if (form.mer_legal_person[0].info[1]) {
                    form.mer_legal_person_list1 = [form.mer_legal_person[0].info[1]];
                }
            } else {
                data.mer_legal_person[0].info = imageList;
                if (form.mer_legal_person[0].type == 2) {
                    form.mer_legal_person_list2 = form.mer_legal_person[0].info;
                } else if (form.mer_legal_person[0].type == 3) {
                    form.mer_legal_person_list3 = form.mer_legal_person[0].info;
                } else if (form.mer_legal_person[0].type == 4) {
                    form.mer_legal_person_list4 = form.mer_legal_person[0].info;
                }
            }
        }
        setForm(data);
    }
    // 上一步
    useEffect(() => {
        if (cancel) {
            dispatch({
                type: 'SET_MERCHANT_INFO',
                data: form
            });
            setCancel(false);
            navigate('/registerMerchant');
        }
    }, [cancel]);
    return <div className='box'>
        <div className='box_header'>红丝婚礼商家中心</div>
        <div className='merchant_main qualification_main'>
            <div className='box_main_model'>
                <div className='model_title'>
                    <div className='title_items title_items_active1'>
                        <div className='title_items_num'>
                            <img src={title_checked} alt=''></img>
                        </div>
                        <div className='title_items_text'>注册账号</div>
                    </div>
                    <div className='title_items_bg title_items_bg_active'></div>
                    <div className='title_items title_items_active1'>
                        <div className='title_items_num'>
                            <img src={title_checked} alt=''></img>
                        </div>
                        <div className='title_items_text'>注册商户</div>
                    </div>
                    <div className='title_items_bg title_items_bg_active'></div>
                    <div className='title_items title_items_active'>
                        <div className='title_items_num'>3</div>
                        <div className='title_items_text'>提交资质</div>
                    </div>
                </div>
                <div className='model_content_card'>
                    <div className='model_content_card_title'>营业执照</div>
                    <div className='model_content'>
                        <span className='model_content_title'><span>*</span>营业执照类型</span>
                        <div className='model_content_main'>
                            <Select defaultValue='企业法人营业执照' style={{ 'width': 544 + 'px', 'height': 50 + 'px' }} onChange={optionChange}>
                                {optionType.map((item, index) => {
                                    return <Option value={item.id} key={item.id}>{item.value}</Option>
                                })}
                            </Select></div>
                    </div>
                    <div className='model_content'>
                        <span className='model_content_title'><span>*</span>营业执照</span>
                        <div className='model_content_main'>
                            <ImgUpload limit='1' size='10485760' value={form.mer_license_list} title='上传照片' onChange={file => successImage(file, 'business_license')}></ImgUpload>
                            <img className='business_license_icon' src={business_license_icon} alt=''></img>
                        </div>
                    </div>
                    <div className='model_content_main_bg'></div>
                    <div className='model_content_card_title'>法人实名认证</div>
                    <div className='model_content'>
                        <span className='model_content_title'><span>*</span>法人身份证明</span>
                        <div className='model_content_main'>
                            <Select defaultValue='居民身份证' style={{ 'width': 544 + 'px', 'height': 50 + 'px' }} onChange={optionChange1}>
                                {optionType1.map((item, index) => {
                                    return <Option value={item.id} key={item.id}>{item.value}</Option>
                                })}
                            </Select></div>
                    </div>
                    <div className='model_content'>
                        <span className='model_content_title'><span>*</span>证件照片</span>
                        <div className='model_content_main' style={{ 'display': 'block', 'marginTop': 12 + 'px' }}>
                            <div className='model_content_main_title1'>需上传<span>营业执照中的法人</span>身份证明<span>，请上传证件照片</span></div>
                            <div className='upload_content_main'>
                                {
                                    form.mer_legal_person.map((item, index) => {
                                        return item.type == 1 ? <div key={index}><div className='upload_content'>
                                            <ImgUpload limit='1' size='10485760' value={form.mer_legal_person_list} title='上传身份证正面' onChange={file => successImage(file, 'identity_card', 1, 0)}></ImgUpload>
                                        </div>
                                            <div className='upload_content'>
                                                <ImgUpload limit='1' size='10485760' value={form.mer_legal_person_list1} title='上传身份证背面' onChange={file => successImage(file, 'identity_card', 1, 1)}></ImgUpload>
                                            </div>
                                            <img className='identity_card_icon' src={identity_card_icon} alt=''></img></div> : form.mer_legal_person[0].type == 2 ? <div key={index}><div className='upload_content'>
                                                <ImgUpload limit='1' size='10485760' value={form.mer_legal_person_list2} title='上传照片' onChange={file => successImage(file, 'identity_card')}></ImgUpload>
                                            </div>
                                                <img className='business_license_icon' src={identity_card_icon2} alt=''></img></div> : form.mer_legal_person[0].type == 3 ? <div key={index}><div className='upload_content'>
                                                    <ImgUpload limit='1' size='10485760' value={form.mer_legal_person_list3} title='上传照片' onChange={file => successImage(file, 'identity_card')}></ImgUpload>
                                                </div>
                                                    <img className='business_license_icon' src={identity_card_icon4} alt=''></img></div> : form.mer_legal_person[0].type == 4 ? <div key={index}><div className='upload_content'>
                                                        <ImgUpload limit='1' size='10485760' value={form.mer_legal_person_list4} title='上传照片' onChange={file => successImage(file, 'identity_card')}></ImgUpload>
                                                    </div>
                                                        <img className='business_license_icon' src={identity_card_icon3} alt=''></img></div> : ''
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='model_content_btn'>
                    <div className='register_merchant_btn register_merchant_btn1' onClick={() => setCancel(true)}>上一步</div>
                    <div className='register_merchant_btn' onClick={() => setAction(true)}>提交</div>
                </div>
            </div>
            <div className='box_main_bottom'> 沪公网安备 3101040200692号 | 沪ICP备17007425号</div>
        </div>
    </div>
}
export default SubmitQualification;

