export default function merchantInfo(data = {
    mer_city_id: '', // 所属城市ID
    mer_area_id: '', // 所属区域ID
    mer_name: '', // 商家名称
    mer_intro: '', // 商家简介
    mer_goods_category: '', // 所属分类ID
    mer_status: 1, // 商家状态 1营业中 2 已关店 3 筹建中 4 暂停营业
    mer_logo: '', // logo
    mer_logo_list: [],
    mer_address: '', // 地址详情
    mer_opening_hours: '', // 营业时间
    mer_phone: '', // 联系方式
    mer_class_attr: '', // 所属分类属性Id
    admin_id: localStorage.getItem('admin_id'), // 所属商户ID
    mer_legal_person: [{ type: 1, info: [] }], // 法人证明
    mer_legal_person_list: [],
    mer_legal_person_list1: [],
    mer_legal_person_list2: [],
    mer_legal_person_list3: [],
    mer_legal_person_list4: [],
    mer_license: [{ type: 1, license_url: '' }], // 营业执照
    mer_license_list: [],
    attr_list: [], // 自定义输入属性
}, action) {
    switch (action.type) {
        case 'SET_MERCHANT_INFO':
            return action.data
        default:
            return data
    }
}