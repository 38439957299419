import { Upload, Modal, message } from 'antd';
import ImgCrop from 'antd-img-crop'; //引入图片裁剪组件
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import api from '../../http/api';
import './upload.css';
function ImgUpload(props) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [nature, setNature] = useState({
    width: props.width || 100,  //裁剪宽度
    height: 100, //裁剪高度
    resize: props.height || 100, //裁剪是否可以调整大小
    resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
    modalTitle: "上传图片", //弹窗标题
    modalWidth: 600, //弹窗宽度
  });
  // 回度数据
  useEffect(()=>{
    let {value=[]} = props;
    let flag = true;
    if (fileList.length > 0) {
      fileList.forEach(item => {
        for(let i=0;i < value.length;i ++) {
          if ((item.response && item.response.data.data != value[i]) || (item.sourceUrl && item.sourceUrl != value[i])) {
            flag = false;
            break;
          }
        }
      });
    } else {
      flag = false;
    }
    if (!flag) {
      setFileList(value.map(item=>({url:api.imgUrl + item,status:'done',sourceUrl:item})));
    }
  },[props.value])
  //根据官方属性定制化裁剪框大小固定的裁剪组件属性
  //base64图片文件
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  //图片预览取消函数
  function handleCancel() {
    setPreviewVisible(false);
  }

  //图片预览弹窗函数
  async function handlePreview(file) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    let image = file.url || file.preview;
    setPreviewImage(image);
    setPreviewVisible(true);
  }
  //上传文件之前
  function beforeUpload(file, fileList) {
    console.log(file.size + '-' + Number(props.size) + '-' + fileList);
    if (props.size && file.size > Number(props.size)) {
      message.error('单张图片内容不能超过10M');
      return false;
    } else {
      return new Promise((resolve, reject) => {
        resolve(file);
      });
    }
  }
  //上传文件改变时的状态，详情可以参考antd的Upload组件API参数
  function onChange(file) {
    setFileList(file.fileList)
    let done = file.fileList.every(item=>item.status==='done');
    if(!done)return;// 没有上传完成
    let arr = file.fileList.map(item => {
      if(item.response){
        return item.response.data.data 
      }else{
        return item.sourceUrl
      };
    });
    props.onChange(arr);
  }
  // 删除图片
  function onRemove(file) {
    console.log(file);
  }
  return (
    <div>
      <ImgCrop {...nature}>
        <Upload
          name="file"
          action={api.uploadImg}
          multiple
          accept="image/*"
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={onChange}
          onRemove={onRemove}
          beforeUpload={beforeUpload}
        >
          {fileList.length >= props.limit ? null : (<div className="glo_upload_img"><PlusOutlined /><span>{props.title}</span></div>)}
          {/* {通过三木运算符判断文件列表fileList的长度来决定上传图片的数量，达到控制图片数量的功能} */}
        </Upload>
      </ImgCrop>
      {/* {图片预览弹出框：可以实时查看上传的图片} */}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel} title='查看图片'>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};
export default ImgUpload