import SlidePages from "../../router/slidePage.js";
import MainSlide from './mainSlide';
import './index.css';
export default function SlideMenus(){
  return <ul className="slideList">
  {
    SlidePages.filter(item=>item.opening).map(item=>{
      return <MainSlide key={item.title} {...item} >
      </MainSlide>
    })
  }
</ul>
}