import {Radio,Input} from 'antd';
import { Fragment, useEffect, useState } from 'react';
// 组件基本数据
const logisticsConfig = [
  {
    label:'无需发货',
    value:'N'
  },
  {
    label:'需要发货',
    value:'Y'
  }
];
const freightConfig = [
  {
    label:'无',
    value:'0'
  },
  {
    label:'本商品多少件包邮',
    value:'1'
  },
  {
    label:'本商品满多少元包邮',
    value:'2'
  },
  {
    label:'本商家满多少元包邮',
    value:'3'
  }
];
const inventoryCount = [
  {
    label:'买家下单减库存',
    value:'0'
  },
  {
    label:'买家付款减库存',
    value:'1'
  }
];
function Logistic(props){
  let {info,onChange,emptyType,isCreate} = props;
  let [logisticsType,setLogisitics] = useState('N');// 发货方式
  let [freightType,setFreightType] = useState('0');// 更多运费选项
  let [freightTitlt,setFeightTitle] = useState('');// 运费选项标题
  let [inventoryType,setInventoryType] = useState('0');// 库存计数类型
  let [expressMoney,setExpressMoney] = useState('');// 运费
  let [expressNum,setExpressNum] = useState('');// 包邮数量
  // 详情数据回填
  useEffect(()=>{
    if(isCreate)return;
    let {goods_express_money_type,goods_is_express,goods_express_money_type_number,goods_express_money,goods_stock} = info;
    setLogisitics(goods_is_express);
    if(typeof goods_is_express === 'undefined' || goods_is_express === 'N')return;
    setFreightType(goods_express_money_type);
    setInventoryType(goods_stock);
    if(goods_is_express === 'Y'){
      setExpressMoney(goods_express_money);
    };
    if(goods_express_money_type !== '0'){
      setExpressNum(goods_express_money_type_number);
    };
  },[info.goods_express_money_type]);
  // 数据变化 向上提交  
  useEffect(()=>{
    let newValue = {
      goods_express_money_type:freightType,
      goods_is_express:logisticsType,
      goods_express_money_type_number:expressNum,
      goods_express_money:expressMoney,
      goods_stock:inventoryType
    };
    onChange(newValue);
  },[logisticsType,freightType,inventoryType,expressMoney,expressNum])
  // 监听运费选项变化设置标题
  useEffect(()=>{
    let title = freightType == '1' ? '多少件包邮' : '满多少元包邮';
    setFeightTitle(title)
  },[freightType]);

  return <div className="input_list">
        <div className="input_item">
          <div className="input_item_title must_item">发货方式</div>
          <div className="input_item_value">
            <Radio.Group options={logisticsConfig} value={logisticsType} onChange={(e)=>{
              setLogisitics(e.target.value);
            }} />
          </div>
        </div>
        {
          logisticsType !== 'Y' ? '' : (<Fragment>
            <div className="input_item">
              <div className="input_item_title must_item">运费</div>
              <div className={`input_item_value cell_row ${emptyType === 'goods_express_money' ? 'error' : ''}`}>
                <Input type="number" placeholder="请输入正整数" value={expressMoney} onChange={(e)=>{
                  setExpressMoney(e.target.value)
                }} />
                <span style={{marginLeft:'10px'}}>元</span>
                <span className="error_info">商品运费不可为空</span>
              </div>
            </div>
            <div className="input_item">
              <div className="input_item_title must_item">更多运费选项</div>
              <Radio.Group options={freightConfig} value={freightType} onChange={(e)=>{
                setFreightType(e.target.value)
              }} />
            </div>
            {
              freightType === '0' ? '' : (<div className="input_item">
              <div className="input_item_title must_item">{freightTitlt}</div>
              <div className={`input_item_value cell_row ${emptyType === 'goods_express_money_type_number' ? 'error':''}`}>
                <Input type="number" placeholder="请输入正整数" value={expressNum} onChange={(e)=>{
                  setExpressNum(e.target.value)
                }} />
                <span style={{marginLeft:'10px'}}>{freightConfig === '1' ? '件': '元'}</span>
                <span className="error_info">{freightTitlt}不可为空</span>
              </div>
            </div>)
            }
          </Fragment>)
        }
        <div className="input_item">
          <div className="input_item_title must_item">库存计数</div>
          <Radio.Group options={inventoryCount} value={inventoryType} onChange={(e)=>{
            setInventoryType(e.target.value);
          }} />
        </div>
      </div>
};
export default Logistic