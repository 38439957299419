const imgUrl = process.env.NODE_ENV === 'prod' ? 'http://img.yusivip.com/' : (process.env.NODE_ENV === 'test' ? 'http://pic.yusivip.com/' : 'http://pic.yusivip.com/');
const BaseUrl = '/api/';//测试
const uploadImg = 'https://api.yusivip.com/merchant/merchant_detail/postUpload';
export default {
  tokenUserInfo: BaseUrl + 'merchant/merchant_admin/tokenUserInfo',
  login: BaseUrl + 'merchant/merchant_admin/toLogin',
  register: BaseUrl + 'merchant/merchant_admin/register',
  getCode:BaseUrl + 'meal/person/LoginCodeSMS',
  getCitys: BaseUrl + 'merchant/Mallcity/getCitys',
  get_class_all: BaseUrl + 'merchant/get_class_all',
  get_attr_all: BaseUrl + 'merchant/get_attr_all',
  getArea: BaseUrl + '/merchant/Mallcity/getArea',
  merchant_register: BaseUrl + 'merchant/merchant_detail/register',
  getGoodsDetail: BaseUrl + 'merchant/Goods/getGoodsFind',// 商品详情
  getClassList: BaseUrl + 'merchant/get_attr_all',
  createGood:BaseUrl + 'merchant/Goods/addnewGoods',
  getGoodList:BaseUrl + 'merchant/Goods/getGoodsList',
  downShelf:BaseUrl +  'merchant/Goods/delGoods',
  editorGoods:BaseUrl+'merchant/Goods/editnewGoods',
  getCaseList:BaseUrl + 'merchant/Casus/getCasusList',
  getCaseDetail:BaseUrl + 'merchant/Casus/getCasusFind',
  createCase:BaseUrl + 'merchant/Casus/addCasus',
  editorCase:BaseUrl + 'merchant/Casus/editCasus',
  getCaseGroup:BaseUrl + 'merchant/casus/getMerGroup',// 获取案例分组
  getPhotoList:BaseUrl + 'merchant/Library/getList', // 获取素材库列表
  addPhotoLibray:BaseUrl + 'merchant/Library/add',
  removePhotoLibray:BaseUrl + 'merchant/Library/del',
  uploadImg,
  imgUrl
};