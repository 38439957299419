import {Input,Checkbox,Radio} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
function BasicInfo(props){
  const dispatch = useDispatch()
  let classList = useSelector(state=>state.classIfyList.list);
  let {info,onChange,isCreate,emptyType} = props;
  let {attr_list=[]} = info;
  // 组件本地数据
  let [goodName,setGoodName] = useState('');
  let [goodSubtitle,setSubtitle] = useState('');
  let [attrData,setAttrData] = useState({});
  // 监听数据回填
  useEffect(()=>{
    let {goods_caption,goods_subtitle} = info;
    setGoodName(goods_caption)
    setSubtitle(goods_subtitle);
  },[info.goods_caption]);
  // 初始化分类属性
  useEffect(()=>{
    let newAttrValue = classList.reduce((source,item)=>{
      let {attr_id} = item;
      source[attr_id] = undefined;
      return source;
    },{});
    if(!isCreate && attr_list.length > 0){
      // 填充数据
      Object.keys(newAttrValue).forEach(attrId=>{
        let {attr_input_mode,list_value} = attr_list.find(item=>item.attr_id == attrId);
        let value;
        if(attr_input_mode === 1 || attr_input_mode === 2){
          value = list_value[0].list_value
        }else if(attr_input_mode === 3){
          value = list_value.find(item=>item.checked).list_id;
        }else if(attr_input_mode === 4){
          value = list_value.filter(item=>item.checked).map(item=>item.list_id);
        };
        newAttrValue[attrId] = value;
      });
    };
    setAttrData(newAttrValue);
  },[classList,info.attr_list]);
  // 分类数据双向绑定变化
  useEffect(()=>{
    let hasData = Object.values(attrData).filter(value=>value).length;
    if(hasData){
      // 存在数据
      let newData = Object.keys(attrData).reduce((source,key)=>{
        let {attr_input_mode} = classList.find(item=>item.attr_id == key);
        let value = attrData[key];
        if(attr_input_mode === 1 || attr_input_mode=== 2){
          // 输入类型
          let newValue = {
            attr_id:key,
            list_value:value || ''
          };
          if(!isCreate){
            // 非新增
            newValue.list_id = attr_list.find(item=>item.attr_id == key).list_value[0].list_id;
          };
          source.attr_list.push(newValue)
        }else{
          // 单选类型
          if(value){
            source.goods_attr_id = source.goods_attr_id.concat(value)
          }
        }
        return source;
      },{attr_list:[],goods_attr_id:[]})
      newData.goods_attr_id = newData.goods_attr_id.join(',');
      console.log('修改分类数据',newData);
      onChange(newData)
    }
  },[attrData]);
  // 商品名称副标题修改·
  useEffect(()=>{
    let newValue = {
      goods_caption:goodName,
      goods_subtitle:goodSubtitle
    };
    onChange(newValue)
  },[goodName,goodSubtitle])
  // 组件方法
  function changeAttrValue(attrId,newVal){
    attrData[attrId] = newVal;
    setAttrData({...attrData});
  };
  // 监听表单数据产生变化向上提交数据
  return <div className="input_list">
  <div className="input_item center">
    <div className="input_item_title must_item">商品标题</div>
    <div className={`input_item_value ${emptyType == 'goods_caption' ? 'error':''}`}>
      <Input showCount maxLength={30} value={goodName} placeholder="请输入商品标题" onChange={(e)=>{
        setGoodName(e.target.value)
      }} />
      <span className="error_info">商品标题不可为空</span>
    </div>
  </div>
  <div className="input_item center">
    <div className="input_item_title">商品副标题</div>
    <div className="input_item_value">
      <Input showCount maxLength={50} placeholder="请输入商品副标题" value={goodSubtitle} onChange={(e)=>{
        setSubtitle(e.target.value);
      }} />
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title">分类属性</div>
    <div className="input_item_value">
      {
        classList.map(item=>{
          let {attr_name,attr_id,attr_input_mode,selectList,attr_is_fill} = item;
          let value = attrData[attr_id]
          let inputDom;
          if(attr_input_mode == 1){
            inputDom = <Input type="number" placeholder='请输入正整数' value={value} onChange={(e)=>{
              changeAttrValue(attr_id,e.target.value)
            }}/>
          }else if(attr_input_mode == 2){
            // 汉字
            inputDom = <Input type="text" value={value} onChange={(e)=>{
              changeAttrValue(attr_id,e.target.value)
            }} />
          }else if(attr_input_mode == 3){
            // 多项单选
            inputDom = <Radio.Group options={selectList} value={value} onChange={(e)=>{
              changeAttrValue(attr_id,e.target.value)
            }} />
          }else if(attr_input_mode == 4){
            // 多项多选
            inputDom = <Checkbox.Group options={selectList} value={value} onChange={(e)=>{
              changeAttrValue(attr_id,e)
            }} />
          }
          return <div className={`radio_row ${emptyType == 'attr_list.'+attr_id ?'error' :''}`} key={attr_id}>
            <span className={`${attr_is_fill === 'Y' ? 'must_item' : ''} radio_title`}>{attr_name}</span>
            {
              inputDom
            }
            <span className="error_info">{attr_name}不可为空</span>
          </div>
        })
      }
    </div>
  </div>
</div>
};
export default BasicInfo;