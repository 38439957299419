import './registerMerchant.css';
import ImgUpload from '../../components/imgUpload';
import title_checked from '../../static/icon/title_checked.png';
import { useState, useEffect, useRef } from 'react';
import http from '../../http/index';
import { message, Select, Radio, Input, Checkbox } from 'antd';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'
const { TextArea } = Input;
const { Option } = Select;
function RegisterMerchant(props) {
    const nameRef = useRef(); // 商家名称
    const introRef = useRef(); // 商家简介
    const addressRef = useRef(); // 详细地址
    const openingHoursRef = useRef(); // 营业时间
    const phoneRef = useRef(); // 联系电话
    const [cityList, setCityList] = useState([]);// 城市列表
    const [classList, setClassList] = useState([]);// 分类列表
    const [attrList, setAttrList] = useState([]);// 分类属性列表
    const [checkboxList, setCheckboxList] = useState([]);// 分类属性多选列表
    const [radioList, setRadioList] = useState('');// 分类属性单选列表
    const [areaList, setAreaList] = useState([]);// 城市地区列表
    const [action, setAction] = useState(false);// 是否提交信息进入下一步
    const [cancel, setCancel] = useState(false);// 上一步
    const dispatch = useDispatch();
    const [form, setForm] = useState(useSelector(state => state.merchantInfo));// 注册的商户信息
    let navigate = useNavigate();
    // 初始化数据
    useEffect(() => {
        getCitys();
        get_class_all();
        if (form.mer_goods_category != '') {
            get_attr_all(form.mer_goods_category);
        }
        if (form.mer_logo != '') {
            form.mer_logo_list = [form.mer_logo];
            setForm(form);
        }
    }, []);
    // 监听提交
    useEffect(() => {
        // 下一步
        if (action) {
            let params = form;
            params.attr_list = [];
            params.mer_class_attr = '';
            attrList.forEach(item => {
                if (item.attr_input_mode == 1 || item.attr_input_mode == 2) {
                    params.attr_list.push({
                        attr_id: item.attr_id,
                        list_value: item.attr_value
                    });
                }
            });
            let arr = checkboxList;
            if (radioList != '') {
                arr.push(radioList);
            }
            params.mer_class_attr = arr.length > 0 ? arr.join(",") : '';
            setAction(false);
            if (params.mer_name == '') {
                message.error('商家名称不能为空');
                return
            }
            if (params.mer_goods_category == '') {
                message.error('商家分类不能为空');
                return
            }
            if (params.mer_class_attr == '' && params.attr_list.length == 0) {
                message.error('更多属性不能为空');
                return
            }
            if (params.mer_logo == '') {
                message.error('商家头像不能为空');
                return
            }
            if (params.mer_address == '' || params.mer_area_id == '') {
                message.error('详细地址不能为空');
                return
            }
            if (params.mer_opening_hours == '') {
                message.error('营业时间不能为空');
                return
            }
            if (params.mer_phone == '') {
                message.error('联系电话不能为空');
                return
            }
            dispatch({
                type: 'SET_MERCHANT_INFO',
                data: form
            });
            navigate('/submitQualification');
        }
    }, [action]);
    // 商家名称 or 商家简介 or 详细地址 or 营业时间 or 联系电话 赋值
    function changeEvent(e, type, attrType, attr_id, list_id) {
        if (type == 'nature') {
            if (attrType == 1 || attrType == 2) {
                let data = { ...attrList };
                let arr = [];
                for (let index in data) {
                    arr.push(data[index]);
                }
                arr.map(item => {
                    if (item.attr_id == attr_id) {
                        if (attrType == 1) {
                            item.attr_value = e.target.value.replace(/[^\d]/g, '');
                        } else {
                            item.attr_value = e.target.value;
                        }
                    }
                    return item
                });
                setAttrList(arr);
            } else if (attrType == 3) {
                setRadioList(e.target.value);
            } else if (attrType == 4) {
                setCheckboxList(e);
            }
        } else {
            let value = e.target.value;
            let data = { ...form };
            if (type == 'nameRef') {
                data.mer_name = value;
            } else if (type == 'introRef') {
                data.mer_intro = value;
            } else if (type == 'status') {
                data.mer_status = e.target.value;
            } else if (type == 'addressRef') {
                data.mer_address = value;
            } else if (type == 'openingHoursRef') {
                data.mer_opening_hours = value;
            } else if (type == 'phoneRef') {
                data.mer_phone = value;
            }
            setForm(data);
        }
    }
    // 获取城市列表
    function getCitys() {
        http.getCitys({ city_id: '*' }).then(res => {
            if (res.code == 200) {
                setCityList(res.data);
                getArea(res.data[0].cityID);
            } else {
                message.error(res.msg);
            }
        });
    }
    // 获取所有分类
    function get_class_all() {
        http.get_class_all({}).then(res => {
            if (res.code == 200) {
                setClassList(res.data.lists);
            } else {
                message.error(res.msg);
            }
        });
    }
    // 获取分类属性
    function get_attr_all(id) {
        http.get_attr_all({ id: id, attr_type: 'MERCHANT' }).then(res => {
            if (res.code == 200) {
                let arr = res.data.lists;
                let arr1 = [];
                for (let item in arr) {
                    arr1.push(arr[item]);
                }
                let arr2 = [];
                let arr3 = [];
                if (form.mer_class_attr == '') {
                    arr1.forEach(item => {
                        if (item.attr_input_mode == 3) {
                            item.list_value.forEach(item1 => {
                                if (item1.checked) {
                                    arr2.push(item1.list_id);
                                }
                            });
                        } else if (item.attr_input_mode == 4) {
                            item.list_value.forEach(item1 => {
                                if (item1.checked) {
                                    arr3.push(item1.list_id);
                                }
                            });
                        }
                    });
                } else {
                    let list = form.mer_class_attr.split(',');
                    arr1.forEach(item => {
                        if (item.attr_input_mode == 3) {
                            item.list_value.forEach(item1 => {
                                for (let i = 0; i < list.length; i++) {
                                    if (item1.checked || list[i] == item1.list_id) {
                                        arr2 = item1.list_id;
                                    }
                                }
                            });
                        } else if (item.attr_input_mode == 4) {
                            item.list_value.forEach(item1 => {
                                for (let i = 0; i < list.length; i++) {
                                    if (item1.checked || list[i] == item1.list_id) {
                                        arr3.push(item1.list_id);
                                    }
                                }
                            });
                        }
                    });
                }
                if (form.attr_list.length > 0) {
                    arr1.map(item => {
                        if (item.attr_input_mode == 1 || item.attr_input_mode == 2) {
                            form.attr_list.forEach(item1 => {
                                if (item.attr_id == item1.attr_id) {
                                    item.attr_value = item1.list_value;
                                }
                            })
                        }
                        return item
                    });
                }
                setRadioList(arr2);
                setCheckboxList(arr3);
                setAttrList(arr1);
            } else {
                message.error(res.msg);
            }
        });
    }
    // 获取城市地区
    function getArea(id) {
        http.getArea({ code_c: id }).then(res => {
            if (res.code == 200) {
                setAreaList(res.data);
                let data = { ...form };
                data.mer_city_id = id;
                data.mer_area_id = res.data[0].code_a;
                setForm(data);
            } else {
                message.error(res.msg);
            }
        });
    }
    // 切换城市
    function cityChange(value) {
        let data = { ...form };
        data.mer_city_id = value;
        setForm(data);
        getArea(value);
    }
    // 切换城市地区
    function areaChange(value) {
        let data = { ...form };
        data.mer_area_id = value;
        setForm(data);
    }
    // 选择商家分类
    function selectClass(id) {
        let data = { ...form };
        data.mer_goods_category = id;
        get_attr_all(id);
        setForm(data);
    }
    // 上传商家图片成功
    function successImage(imageList) {
        form.mer_logo_list = imageList;
        form.mer_logo = imageList[0];
        setForm(form);
    }
    // 上一步
    useEffect(() => {
        if (cancel) {
            dispatch({
                type: 'SET_MERCHANT_INFO',
                data: form
            });
            setCancel(false);
            navigate('/register');
        }
    }, [cancel]);
    return <div className='box'>
        <div className='box_header'>红丝婚礼商家中心</div>
        <div className='merchant_main'>
            <div className='box_main_model'>
                <div className='model_title'>
                    <div className='title_items title_items_active1'>
                        <div className='title_items_num'>
                            <img src={title_checked} alt=''></img>
                        </div>
                        <div className='title_items_text'>注册账号</div>
                    </div>
                    <div className='title_items_bg title_items_bg_active'></div>
                    <div className='title_items title_items_active'>
                        <div className='title_items_num'>2</div>
                        <div className='title_items_text'>注册商户</div>
                    </div>
                    <div className='title_items_bg'></div>
                    <div className='title_items'>
                        <div className='title_items_num'>3</div>
                        <div className='title_items_text'>提交资质</div>
                    </div>
                </div>
                <div className='model_content'>
                    <span className='model_content_title'><span>*</span>商家名称</span>
                    <div className='model_content_main'>
                        <Input ref={nameRef} value={form.mer_name} type='text' maxLength='50' placeholder='请输入商家名称，最多50字' className='model_content_inp' onChange={(e) => changeEvent(e, 'nameRef')} />
                    </div>
                </div>
                <div className='model_content'>
                    <span className='model_content_title'><span>*</span>所属城市</span>
                    <div className='model_content_main'>
                        <Select value={form.mer_city_id} style={{ 'width': 544 + 'px', 'height': 50 + 'px' }} onChange={cityChange}>
                            {cityList.map((item, index) => {
                                return <Option value={item.cityID} key={item.cityID}>{item.city}</Option>
                            })}
                        </Select></div>
                </div>
                <div className='model_content model_content1'>
                    <span className='model_content_title'><span>*</span>商家分类</span>
                    <div className='model_content_main model_content_main1'>
                        <div className='model_content_main_title'>商户创建成功后，不能再修改行业分类</div>
                        <div className='model_content_main_item'>
                            {classList.map((item, index) => {
                                return <div className={['model_content_main_items', form.mer_goods_category == item.id ? 'model_content_main_items_active' : null].join(' ')} key={item.id} onClick={selectClass.bind(this, item.id)}>{item.title}</div>
                            })}
                        </div>
                    </div>
                </div>
                {attrList.length > 0 ? <div className='model_content'>
                    <span className='model_content_title' style={{ marginTop: 0 }}><span>*</span>更多属性</span>
                    <div className='model_content_main model_content_main1'>
                        {attrList.length > 0 ? attrList.map(item => {
                            return <div className='model_content_main_natures' key={item.attr_id}>
                                <div className='natures_title'>{item.attr_name}</div>
                                <div className='natures_list'>
                                    {
                                        item.attr_input_mode == 1 ? <Input value={item.attr_value} type='text' className='model_content_inp natures_inp' onChange={(e) => changeEvent(e, 'nature', 1, item.attr_id)} /> :
                                            item.attr_input_mode == 2 ? <Input value={item.attr_value} type='text' className='model_content_inp natures_inp' onChange={(e) => changeEvent(e, 'nature', 2, item.attr_id)} /> :
                                                item.attr_input_mode == 3 ? <Radio.Group onChange={(e) => changeEvent(e, 'nature', 3)} value={radioList}>
                                                    {item.list_value.map(item1 => {
                                                        return <Radio value={item1.list_id} key={item1.list_id}>{item1.list_value}</Radio>
                                                    })}
                                                </Radio.Group> : item.attr_input_mode == 4 ? <Checkbox.Group onChange={(e) => changeEvent(e, 'nature', 4)} value={checkboxList}>
                                                    {item.list_value.map(item1 => {
                                                        return <Checkbox value={item1.list_id} key={item1.list_id}>{item1.list_value}</Checkbox>
                                                    })}
                                                </Checkbox.Group> : ''
                                    }
                                </div>
                            </div>
                        }) : ''}
                    </div>
                </div> : ''}
                <div className='model_content'>
                    <span className='model_content_title' style={{ marginTop: 0 }}><span>*</span>商家营业状态</span>
                    <div className='model_content_main'>
                        <Radio.Group onChange={(e) => changeEvent(e, 'status')} value={form.mer_status}>
                            <Radio value={1}>营业中</Radio>
                            <Radio value={2}>已关店</Radio>
                            <Radio value={3}>筹建中</Radio>
                            <Radio value={4}>暂停营业</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className='model_content'>
                    <span className='model_content_title'><span>*</span>商家头像</span>
                    <div className='model_content_main'>
                        <ImgUpload width='200' height='200' limit='1' title='选择图片' value={form.mer_logo_list} onChange={file => successImage(file)}></ImgUpload>
                        {/* <ImgUpload width='200' height='200' limit='1' title='选择图片' onChange={file => successImage(file)}></ImgUpload> */}
                        <div className='img_point'>建议尺寸200*200（1:1）</div>
                    </div>
                </div>
                <div className='model_content'>
                    <span className='model_content_title'>商家简介</span>
                    <div className='model_content_main'>
                        <TextArea ref={introRef} value={form.mer_intro} placeholder='限制220字' maxLength='220' className='model_content_textarea' onChange={(e) => changeEvent(e, 'introRef')} />
                    </div>
                </div>
                <div className='model_content'>
                    <span className='model_content_title'><span>*</span>详细地址</span>
                    <div className='model_content_main'>
                        <Select value={form.mer_area_id} style={{ 'width': 147 + 'px', 'height': 50 + 'px' }} onChange={areaChange} placeholder='选择区域'>
                            {areaList.map((item, index) => {
                                return <Option value={item.code_a} key={item.code_a}>{item.name}</Option>
                            })}
                        </Select>
                        <Input ref={addressRef} value={form.mer_address} type='text' placeholder='请输入详细地址' className='model_content_inp model_content_inp2' onChange={(e) => changeEvent(e, 'addressRef')} />
                    </div>
                </div>
                <div className='model_content'>
                    <span className='model_content_title'><span>*</span>营业时间</span>
                    <div className='model_content_main'>
                        <Input ref={openingHoursRef} value={form.mer_opening_hours} type='text' maxLength='30' placeholder='可以输入不超过30个汉字' className='model_content_inp' onChange={(e) => changeEvent(e, 'openingHoursRef')} />
                    </div>
                </div>
                <div className='model_content'>
                    <span className='model_content_title'><span>*</span>联系电话</span>
                    <div className='model_content_main'>
                        <Input ref={phoneRef} value={form.mer_phone} type='text' maxLength='30' placeholder='可以输入不超过30个汉字' className='model_content_inp' onChange={(e) => changeEvent(e, 'phoneRef')} />
                    </div>
                </div>
                <div className='model_content_btn'>
                    <div className='register_merchant_btn register_merchant_btn1' onClick={() => setCancel(true)}>上一步</div>
                    <div className='register_merchant_btn' onClick={() => setAction(true)}>下一步</div>
                </div>
            </div>
            <div className='box_main_bottom'> 沪公网安备 3101040200692号 | 沪ICP备17007425号</div>
        </div>
    </div>
}
export default RegisterMerchant;

