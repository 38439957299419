import {Checkbox} from 'antd';
import { useEffect, useState } from 'react';
const serviceConfig = [
  {label:'提供发票',value:'1'},
  {label:'随时可退',value:'2'},
  {label:'过期可退',value:'3'},
  {label:'支持7天无理由退款',value:'4'}
]
function AfterSale(props){
  let {info,onChange} = props;
  let [service,setService] = useState([]);// 售后服务类型
  useEffect(()=>{
    let {goods_sale=[]} = info;
    goods_sale = goods_sale.filter(item=>item);
    setService(goods_sale)
  },[info.goods_sale]);
  // 数据变化向上提交
  useEffect(()=>{
    onChange({goods_sale:service})
  },[service])
  return <div className="input_list">
    <div className="input_item">
      <div className="input_item_title">售后服务</div>
      <Checkbox.Group options={serviceConfig} value={service} onChange={setService}/>
    </div>
  </div>;
};
export default AfterSale;