function SelectBlock(props){
  let {title,subTitle,current,onChange,value} = props;
  return <div className={`check_block ${current === value ? 'active' :''}`} onClick={()=>{
    onChange(value)
  }}>
    <p className="check_box_title">{title}</p>
    <p className="check_box_subtitld">{subTitle}</p>
    <div className="acive_icon">
      <img src={require('../../../static/icon/select_icon.png')} />
    </div>
  </div>
};
export default SelectBlock;