function checkNumber(value){

}
const notEmpty = [''];
// 基本
export default function checkPostData(data,setEmptyType,classList,groupList){
  // 校验非空
  let {
    casus_title,// 案例标题
    attr_list,// 分类属性
    casus_drawing,// 案例主图
    casus_show_img,// 案例展示图
    casus_status,// 上架类型
    casus_start_time,// 上架开始时间
    casus_end_time,// 上架结束时间
    casus_attr_id,// 分类属性选中ID
    group_id
  } = data;
  // 商品标题
  if(!casus_title){
    setEmptyType('casus_title')
    return;
  }
  // 案例分组
  if(groupList.length > 0 && !group_id){
    // 存在案例分组
    setEmptyType('group_id')
    console.log('阻断');
    return;
  }
  // 校验分类属性
  for(let i=0;i<classList.list.length;i++){
    let {attr_is_fill,attr_id,attr_input_mode,selectList} = classList.list[i];// 原始对照表
    if(attr_is_fill === 'Y'){
      // 当前项是必填
      if(attr_input_mode === 1 || attr_input_mode === 2){
        let {list_value} = attr_list.find(item=>item.attr_id);
        if(!list_value){
          setEmptyType('attr_list.' + attr_id)
          return
        }
        // 
      }else{
        let hasData = selectList.some(item=>casus_attr_id.includes(item.value));
        if(!hasData){
          setEmptyType('attr_list.' + attr_id);
          return;
        }
      }
    }
  };
  // 图文描述
  if(!casus_drawing) {// 未上传头图
    setEmptyType('casus_drawing')
    return;
  }
  if(casus_show_img.length <= 0){// 展示图未上传
    setEmptyType('goods_show_img')
    return;
  }
  //  上架信息
  if(casus_status === '2'){
    let hasData = [casus_start_time,casus_end_time].every(value=>value)
    if(!hasData){
      setEmptyType('shelfTimer')
      return;
    }
  }
  setEmptyType(undefined)
  return data;
};