import { useSelector } from 'react-redux';
import { Outlet,Navigate} from 'react-router-dom';
import {SlideMenus,Header} from '../../components/index';
import './index.css';
function MianPage(){
  let store = useSelector(state=>state.user);
  console.log('用户信息',store);
  if(Object.keys(store).length <= 0){
    return <Navigate to="/login" />
  }
  return <div className="page_content">
  <Header></Header>
  <div className="main_page">
    <SlideMenus></SlideMenus>
    <div className="main_page_content">
      <Outlet />
    </div>
  </div>
</div>
}
export default MianPage;