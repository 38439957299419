import {post,get,exportExcel,QiniuUpload} from './axios';
import api from './api';
export default {
  // 登录
  login(params){
    return post(api.login,params)
  },
  // 登录
  tokenUserInfo(params){
    return post(api.tokenUserInfo,params)
  },
  // 注册
  register(params){
    return post(api.register,params)
  },
  // 注册时获取验证码
  getCode(params){
    return post(api.getCode,params)
  },
  // 获取城市列表
  getCitys(params) {
    return post(api.getCitys,params)
  },
  // 获取所有分类
  get_class_all(params) {
    return post(api.get_class_all,params)
  },
  // 获取分类属性
  get_attr_all(params) {
    return get(api.get_attr_all,params)
  },
  // 获取城市地区
  getArea(params) {
    return post(api.getArea,params)
  },
  // 注册商户 or 修改商户
  merchant_register(params) {
    return post(api.merchant_register,params)
  },
  getGoodsDetail(params){
    return post(api.getGoodsDetail,params)
  },
  getShopClassList(params){
    return get(api.getClassList,params)
  },
  uploadImg(params,options={}){
    return post(api.uploadImg,params,options)
  },
  createGood(params){
    return post(api.createGood,params)
  },
  getGoodsList(params){
    return post(api.getGoodList,params)
  },
  downShelf(params){
    return post(api.downShelf,params)
  },
  editorGoods(params){
    return post(api.editorGoods,params)
  },
  getClassList(params){
    return post(api.getCaseList,params)
  },
  getCaseDetail(params){
    return post(api.getCaseDetail,params)
  },
  createCase(params){
    return post(api.createCase,params)
  },
  editorCase(params){
    return post(api.editorCase,params)
  },
  getCaseGroup(params){
    return get(api.getCaseGroup,params)
  },
  getPhotoList(params){
    return post(api.getPhotoList,params)
  },
  addPhotoLibray(params){
    return post(api.addPhotoLibray,params)
  },
  removePhotoLibray(params){
    return post(api.removePhotoLibray,params)
  }
}