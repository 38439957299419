import SelectBlock from './selectBlock';
import {Input,Radio,Select} from 'antd';
import { Fragment, useEffect, useState } from 'react';
const {Option} = Select;
function OrderAttr(props){
  let {info,onChange,isCreate,emptyType} = props;
  let [salesType,setSalesType] = useState(0);// 是否能下单
  let [paymentMoney,setPaymentMoney] = useState('');// 预约金金额
  let [paymentPrice,setPaymentPrice] = useState('');// 预约金抵扣金额
  let [minPayNum,setMinpayNum] = useState(1);// 最低购买数量
  let [astrictBuy,setAstrictBuy] = useState('0');// 是否限购
  let [isIntrgral,setIsIntrgral] = useState('0');// 是否可以使用积分
  let [intrgral,setIntrgral] = useState('');// 最大可用积分
  let [maxPayType,setMaxPayType] = useState('day');// 最多限购类型
  let [maxPayNum,setMaxPayNum] = useState('');// 最大购买数量
  // 详情数据回填发生变化
  useEffect(()=>{
    if(isCreate)return;
    let {goods_is_payment,goods_payment_money,goods_payment_price,goods_mini_buy_number,goods_is_astrict_buy,goods_is_integral,goods_max_integral,goods_astrict_number,goods_astrict_period} = props.info;
    setSalesType(goods_is_payment);// 商品类型
    if(typeof goods_is_payment === 'undefined' || goods_is_payment === 0)return;
    setMinpayNum(goods_mini_buy_number);// 最低购买书
    setAstrictBuy(goods_is_astrict_buy);// 是否限购
    setIsIntrgral(goods_is_integral);// 是否可以使用积分
    setIntrgral(goods_max_integral);// 使用积分数量
    if(goods_is_astrict_buy === '1'){
      setMaxPayNum(goods_astrict_number);
      setMaxPayType(goods_astrict_period)
    }
    if(goods_is_payment === 2){
      setPaymentMoney(goods_payment_money);
      setPaymentPrice(goods_payment_price);
    }
  },[info.goods_is_payment]);
  // 监听预约金价格
  useEffect(()=>{
    if(paymentMoney > paymentPrice){
      setPaymentPrice(paymentMoney);
    };
  },[paymentMoney,paymentPrice]);
  // 监听最低最多购买数量 最大购买数量小于等于最低购买数量增加最大购买数量
  useEffect(()=>{
    if(maxPayNum <= minPayNum){
      setMaxPayNum(minPayNum + 1)
    }
  },[maxPayNum,minPayNum]);
  // 表单数据变化 向上提交
  useEffect(()=>{
    let newVale = {
      goods_is_payment:salesType,// 商品类型
      goods_payment_money:paymentMoney,// 预约金价格
      goods_payment_price:paymentPrice,// 预约金预约金抵扣金额
      goods_mini_buy_number:minPayNum,// 最低购买数量
      goods_is_astrict_buy:astrictBuy,// 是否限购
      goods_astrict_number:maxPayNum,// 最大购买数量
      goods_is_integral:isIntrgral,// 是否能积分抵扣
      goods_max_integral:intrgral,// 最大可用积分
      goods_astrict_period:maxPayType,// 最大购买类型
    };
    onChange(newVale)
  },[salesType,minPayNum,astrictBuy,maxPayType,maxPayNum,isIntrgral,intrgral,paymentMoney,paymentPrice])
  // 组件方法
  function setNumber(e,targetFn){
    let value = parseInt(e.target.value) || 0;
    targetFn(value);
  };

  // 组件静态数据
  const checkList = [
    {
      label:'无需下单',
      info:'线上预约',
      value:0
    },
    {
      label:'正常商品',
      info:'标价下单',
      value:1
    },
    {
      label:'预约金商品',
      info:'预约金下单',
      value:2
    }
  ];
  const maxPayCtrl = [
    {
      label:'不限制购买数量',
      value:'0'
    },
    {
      label:'限制单用户购买数量',
      value:'1'
    }
  ];
  const isIntegralConfig = [
    {
      label:'不可抵扣',
      value:'0'
    },
    {
      label:'可以使用红丝积分',
      value:'1'
    }
  ];
  const maxPayTypeConfig = [
    {
      label:'每天',
      value:'day'
    },
    {
      label:'每周',
      value:'week'
    },
    {
      label:'每月',
      value:'month'
    }
  ]
  return <div className="input_list">
  <div className="input_item">
    <div className="input_item_title must_item" style={{marginTop:'11px'}}>是否能下单</div>
    <div className="input_item_value row">
      {
        checkList.map(item=>{
          return <SelectBlock key={item.label} title={item.label} subTitle={item.info} value={item.value} current={salesType} onChange={(e)=>{
            setSalesType(e);
          }}/>
        })
      }
    </div>
  </div>
  {
    salesType !== 2 ? '' :  (<Fragment>
      <div className="input_item center">
    <div className="input_item_title must_item">预约金价格</div>
    <div className={`input_item_value ${emptyType  === 'goods_payment_money' ? 'error' : ''}`}>
      <Input placeholder="请输入正整数" value={paymentMoney} onChange={(e)=>{
        setNumber(e,setPaymentMoney);
      }} />
      <span className="error_info">预约金价格不可为空</span>
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">预约金抵扣价格</div>
    <div className="input_item_value">
      <Input placeholder="请输入正整数，默认填写预约金额" value={paymentPrice} onChange={(e)=>{
        setNumber(e,setPaymentPrice);
      }} />
      <p className="text_info">预约抵扣价格默认=预约金价格。就表示纯预约，无抵扣。抵扣价格必须大于等于预约金。</p>
      <p className="text_info2">举例：预约金200元，抵扣价格1000元。<br/>预约金200元，抵扣价格为200元。</p>
    </div>
  </div>
    </Fragment>)
  }
  {
    salesType === 0 ? '' : (<Fragment>
      <div className="input_item">
    <div className="input_item_title must_item">最低需购买数量</div>
    <div className="input_item_value cell_row">
      <Input style={{width:80}} type="number" value={minPayNum} onChange={(e)=>{
        setNumber(e,setMinpayNum)
      }} />
      <span style={{marginLeft:'10px'}}>件</span>
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">限制购买数量</div>
    <div className="input_item_value">
      <div>
        <Radio.Group options={maxPayCtrl} value={astrictBuy} onChange={(e)=>{
          setAstrictBuy(e.target.value);
        }} />
      </div>
      {
        astrictBuy !== '1' ? '' :(<div className="pay_limit">
        <span className="pay_limit_info" style={{marginRight:'10px'}}>按周期限购</span>
        <Select style={{width:'80px',marginRight:'20px'}} value={maxPayType} onChange={setMaxPayType}>
          {
            maxPayTypeConfig.map(({label,value})=>{
              return <Option key={label} value={value}>{label}</Option>
            })
          }
        </Select>
        <Input placeholder="正整数" type="number" style={{width:'80px'}} value={maxPayNum} onChange={(e)=>{
          setNumber(e,setMaxPayNum);
        }} />
        <span style={{marginLeft:'10px'}}>件</span>
      </div>)
      }
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">积分抵扣现金</div>
    <div className="input_item_value">
      <div>
        <Radio.Group options={isIntegralConfig} value={isIntrgral} onChange={(e)=>{
          setIsIntrgral(e.target.value);
        }} />
      </div>
      {
        isIntrgral !== '1' ? '' : (<div className="pay_limit">
        <span className="pay_limit_info" style={{marginRight:'10px'}}>最大可使用积分数</span>
          <Input style={{width:'80px'}} placeholder="不限制" type="number" value={intrgral} onChange={(e)=>{
            setNumber(e,setIntrgral);
          }} />
        </div>) 
      }
      
    </div>
  </div>
    </Fragment>)
  }
  
</div>
};

export default OrderAttr;