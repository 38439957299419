import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import SubSlide from './subSlide';
function MainSlider(props){
  let [menuOpen,setMenuOpen] = useState(false);// 二级菜单是否打开
  let [currentPage,setCurrentPage] = useState(false);
  let {title,icon,children,activeIcon} = props;
  let {pathname}  = useLocation();
  let [pathList] = useState(children.map(item=>item.path));
  useEffect(()=>{// 监听页面变化 设置默认菜单打开
    pathname = pathname.split('/')
    let isCurrent = pathname.some(path=>pathList.includes(path))
    if(isCurrent){
      setCurrentPage(true);
      setMenuOpen(true)
    }else{
      setCurrentPage(false)
      setMenuOpen(false)
    }
  },[pathname]);
  return <li onClick={()=>{
    setMenuOpen(!menuOpen)
  }}>
    <div className={`firstMenu ${currentPage ?'active' :''}`}>
      {icon ? <img className="slide_icon" src={currentPage ? activeIcon : icon} alt=""/>:''}
        <span>{title}</span>
      {children ? <img className={`open_icon ${menuOpen ? 'open':''}`} src={require('../../static/icon/down.png')} /> : ''}
    </div>
    <SubSlide children={children} open={menuOpen}></SubSlide>
</li>
};
export default MainSlider;