import { Alert } from "antd";
import React from "react";
import reactDom from "react-dom";
function setTableCellCenter(list=[]){
  if(typeof list !== 'object' && !Array.isArray(list)){
    return list;
  };
  
  return list.map(item=>{
    item.align='center';
    return item
  });
};
function message(options={type:'success',duration:1500}){
  reactDom.render(<Alert message={options.message} type={options.type} />,document.getElementById('message'))
  setTimeout(() => {
    reactDom.render('',document.getElementById('message'))
  }, options.duration || 1500);
};
// 随机字符串生成
function randomStr(){
  return [...'.'.repeat(10)].map(item=>{
    let random = Math.ceil(Math.random() * 26) + 64;
    return String.fromCharCode(random)
  }).join('')
}
export {setTableCellCenter,message,randomStr}