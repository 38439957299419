function checkNumber(value){

}
const notEmpty = [''];
// 基本
export default function checkPostData(data,setEmptyType,classList){
  // 校验非空
  let {
    attr_list,
    goods_attr_id='',
    goods_spec,
    goods_sell_nature,
    goods_is_payment,
    goods_is_astrict_buy,
    goods_is_integral,
    goods_max_integral,
    goods_is_express,
    goods_express_money_type,
    goods_express_money,
    goods_express_money_type_number,
    goods_header_img,
    goods_show_img=[],
    goods_payment_money,
    goods_caption,
    goods_deploy,
    goods_status_timing,
    goods_end_timing
  } = data;
  // 商品标题
  if(!goods_caption){
    setEmptyType('goods_caption')
    return;
  }
  // 校验分类属性
  for(let i=0;i<classList.list.length;i++){
    let {attr_is_fill,attr_id,attr_input_mode,selectList} = classList.list[i];// 原始对照表
    if(attr_is_fill === 'Y'){
      // 当前项是必填
      if(attr_input_mode === 1 || attr_input_mode === 2){
        let {list_value} = attr_list.find(item=>item.attr_id);
        if(!list_value){
          setEmptyType('attr_list.' + attr_id)
          return
        }
        // 
      }else{
        let hasData = selectList.some(item=>goods_attr_id.includes(item.value));
        if(!hasData){
          setEmptyType('attr_list.' + attr_id);
          return;
        }
      }
    }
  };
  // 校验sku
  if(goods_sell_nature.length <= 0){
    setEmptyType('sku_list')
    return;
  }
  if(goods_spec === '1'){
    // 单规格
    console.log('校验单规格',goods_sell_nature);
    let {attrbute_money,attrbute_price,attrbute_store} = goods_sell_nature[0]
    let hasData = [attrbute_money,attrbute_price,attrbute_store].every(item=>item)
    if(!hasData){
      setEmptyType('sku_list')
      return
    }
  }else{
    // 多规格
    let checkKey = ['attrbute_volume','attrbute_store','attrbute_price','attrbute_money']
    let hasData = goods_sell_nature.every(row=>{
      return checkKey.every(key=>row[key])
    })
    if(!hasData){
      setEmptyType('sku_list')
      return;
    }
  };
  // 校验订单属性
  if(goods_is_payment === 0){
    // 无需下单商品 --> 删除除下单属性 其他订单属性删除
    delete data.goods_astrict_number;// 删除最大购买数量
    delete data.goods_payment_money;// 删除预约金价格
    delete data.goods_payment_price;// 删除预约金抵扣金额
    delete data.goods_mini_buy_number;// 删除最低购买数量
    delete data.goods_is_astrict_buy;// 删除最大限购开关
    delete data.goods_is_integral;// 删除开启积分抵扣
    delete data.goods_max_integral;// 删除积分数量
  }else{
    // 正常商品 未开启的数据删除 积分 最高限购
    if(goods_is_astrict_buy === '0'){// 未开启最大限购
      delete data.goods_astrict_number
    };
    if(goods_is_integral === '0'){// 未开启积分抵扣
      delete data.goods_is_integral
    }else if(goods_max_integral === ''){// 开启积分抵扣 没有输入积分数量
      delete data.goods_is_integral;
    }
    if(goods_is_payment === 2){// 预约金商品
      if(!goods_payment_money){// 未输入预约金价格
        setEmptyType('goods_payment_money')
        return;
      }
    }
  };
  // 图文描述
  if(!goods_header_img) {// 未上传头图
    setEmptyType('goods_header_img')
    return;
  }
  if(goods_show_img.length <= 0){
    setEmptyType('goods_show_img')
    return;
  }
  data.goods_show_img = goods_show_img.join(',');
  // 物流信息
  if(goods_is_express === 'Y'){
    if(goods_express_money === ''){// 未填写运费
      setEmptyType('goods_express_money');
      return;
    }
    if(goods_express_money_type !=='0'){// 开启更多运费选项
      if(!goods_express_money_type_number){
        setEmptyType('goods_express_money_type_number');
        return;
      }
    }else{// 未开启更多运费
      delete data.goods_express_money_type_number;// 删除包邮条件
    }
  }else{
    // 不需要发货 删除部分属性
    delete data.goods_express_money;// 删除运费
    delete data.goods_express_money_type;// 删除运费选项
    delete data.goods_express_money_type_number;// 删除包邮条件
  };
  //  上架信息
  if(goods_deploy === '2'){
    let hasData = [goods_status_timing,goods_end_timing].every(value=>value)
    if(!hasData){
      setEmptyType('shelfTimer')
      return;
    }
  }
  setEmptyType(undefined)
  return data;
};