import './index.css';
import { useEffect, useState } from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Radio,Table,Popconfirm} from 'antd';
import request from '../../../http/index';
import { useNavigate } from 'react-router';
import {message} from '../../../utils/tool';
function CaseMange(props){
  const [page,setPage] = useState(1);// 当前页
  var [limit,setLimit] = useState(10);// 条数
  var [goodsDeploy,setGoodsDeploy] = useState('');// 上架状态
  var [goodsName,setGoodsName] = useState('');
  var [total,setTotal] = useState(0);
  var [goodsList,setGoodsLis] = useState([])
  let navigate = useNavigate()
  function editGood(e){
    let {id} = e;
    let targetUrl = '/main/CaseDetail';
    if(typeof id !=='undefined'){
      targetUrl += '?id='+ id;
    }
    navigate(targetUrl)
  };
  const columns = [
    {
      title:'排序',
      align:'center',
      width:80,
      render:(col,row,index)=>{
        return <span>{(page-1) * 10 + index+1}</span>
      }
    },
    {
      title:'ID',
      align:'center',
      width:80,
      dataIndex:'id'
    },
    {
      title:'案例封面',
      align:'center',
      dataIndex:'casus_drawing',
      width:132,
      render:(text)=>{
        return <img className="shop_img" src={text} />
      }
    },
    {
      title:'案例名称',
      align:'center',
      dataIndex:'casus_title'
    },
    {
      title:'支持桌数',
      align:'center',
      dataIndex:'attr_name'
    },
    {
      title:'创建时间',
      align:'center',
      dataIndex:'casus_addtime'
    },
    {
      title:'更新时间/状态',
      align:'center',
      render:(row)=>{
        /**
         * v-if="scope.row.goods_deploy == 1"
         * v-if="scope.row.goods_deploy == 2"
         * v-if="scope.row.goods_deploy == 3"
         */
        let {casus_status,goods_update_time} = row;
        return <div className="status">
          <p>{goods_update_time}</p>
          <p className={casus_status !== '3' ? 'status_success' :'status_full'}>{['','上架中','定时上架','仓库中'][casus_status]}</p>
        </div>
      }
    },
    {
      title:'操作',
      align:'center',
      width:190,
      render:(row)=>{
        let {casus_status} = row;
        let editBtn,editInfo;
        if(casus_status === '1'){
          // 上架状态
          editBtn = <div className="table_btn off_btn">下架</div>
          editInfo = '该案例当前为上架状态，确定要下架该案例吗？';
        }else if(casus_status === '3'){
          // 仓库中
          editBtn = <div className="table_btn off_btn">上架</div>
          editInfo = '该商品当前为案例状态，确定要上架该案例吗？'
        }
        return <div className="table_action">
          <div className="table_btn edit_btn" onClick={()=>{
            editGood(row)
          }}>编辑</div>
          {
            typeof editBtn === 'undefined' ? '' :(<Popconfirm
              placement="topRight"
              title={editInfo}
              onConfirm={changeShelf.bind(row)}
              okText="确认"
              cancelText="取消"
            >
              {editBtn}
            </Popconfirm>)
          }
        </div>
      }
    }
  ];
  // 上下架操作
  function changeShelf(){
    let {id,goods_deploy} = this;
    request.downShelf({id})
    .then(res=>{
      message({
        type:'success',
        message:goods_deploy === '1' ? '下架成功':'上架成功'
      });
      getGoodsList()
    })
  };
  useEffect(()=>{
    getGoodsList();
  },[limit,page,goodsDeploy])
  function getGoodsList(){
    let queryData = {
      casus_city_id:'310100', // 商家城市
      casus_mer_id:675,// 商家ID
      casus_title:goodsName,
      limit, // 条数
      page, // 当前页
      casus_status:goodsDeploy
    };
    request.getClassList(queryData)
    .then(res=>{
      let {data,count} = res;
      setTotal(count);
      setGoodsLis(data);
    })
  };
  return <div className="goods_mange">
    <div className="header_info">
      <div className="page_title">案例管理</div>
      <div className="add_goods_btn" onClick={editGood}>
        <PlusOutlined />
        <span className="add_good_btn_text">新增案例</span>
      </div>
    </div>
    <div className="filter_info">
      <div className="status_filter">
        <Radio.Group value={goodsDeploy} onChange={(e)=>{
          setGoodsDeploy(e.target.value)
        }} buttonStyle="slide">
          <Radio.Button value="">全部</Radio.Button>
          <Radio.Button value={1}>上架中</Radio.Button>
          <Radio.Button value={3}>仓库中</Radio.Button>
        </Radio.Group>
      </div>
      <div className="search_filter">
        <input placeholder="案例名称或案例ID" className="search_input" onChange={(e)=>{
          setGoodsName(e.target.value)
        }} value={goodsName} />
        <div className="search_btn" onClick={getGoodsList}>搜索</div>
      </div>
    </div>
    <div className="table_data_info">
      <Table 
        bordered={true} 
        dataSource={goodsList} 
        columns={columns} 
        rowKey={row=>row.id}
        pagination={{
          total,
          pageSize:limit,
          current:page,
          pageSize:limit,
          onChange(page,pageSize){
            setLimit(pageSize);
            setPage(page);
          }
        }}
      />
    </div>
  </div>
};
export default CaseMange;