import './renovation.css';
import { useDispatch } from "react-redux";

function Renovation(props){
  return <div className="renovation">
    <div className="prev_view">
      <div className="prev_bg">
        <img src={require('../../static/renovation_bg.png')} />
      </div>
      <div className="prev_title">预览区</div>
      <div className="prev_content">
        <div className="swiper_content">
          <div className="swiper_bg">
            
          </div>
          <div className="swiper">
            <img src="" />
          </div>
        </div>
      </div>
    </div>
    <div className="ctrl_view"></div>
  </div>
};
export default Renovation;