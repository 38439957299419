import { useEffect, useState } from 'react';
import { ImgUpload,TextEditor } from '../../../components';
function PicDesc(props){
  let {info,onChange,emptyType,isCreate} = props;
  let [headImg,setHeadImg] = useState([]);// 头图
  let [loopImg,setLoopImg] = useState([]);// 轮播图
  let [descption,setDescption] = useState('');// 图文介绍
  let [buyKnow,setBuyKnow] = useState('');// 购买须知
  useEffect(()=>{
    if(isCreate)return;
    let {goods_header_img,goods_show_img=[],goods_notice='',goods_detail=''} = info;
    setHeadImg([goods_header_img]);
    setLoopImg(goods_show_img.filter(item=>item));
    setDescption(goods_detail);
    setBuyKnow(goods_notice)
  },[info.goods_header_img]);
  // 数据变化向上通知
  useEffect(()=>{
    let newValue = {
      goods_header_img:headImg.join(''),
      goods_show_img:loopImg,
      goods_notice:buyKnow,
      goods_detail:descption
    };
    onChange(newValue);
  },[headImg,loopImg,descption,buyKnow])
  return <div className="input_list">
  <div className="input_item">
    <div className="input_item_title must_item">上传主图</div>
    <div className={`upload_value ${emptyType === 'goods_header_img' ? 'error' : ''}`}>
      <p className="upload_info_text">展示在商家详情页的商品封面，最佳尺寸为670*670像素，比例为1:1。<span className="form_blue_color">（仅支持上传一张）</span></p>
      <ImgUpload value={headImg} limit={1} onChange={(e)=>{
        setHeadImg(e);
      }} />
      <span className="error_info">商品主图不可为空</span>
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">上传轮播图</div>
    <div className={`upload_value ${emptyType === 'goods_show_img' ? 'error' : ''}`}> 
      <p className="upload_info_text">展示在案例详情页的顶部横幅处，最佳尺寸为1372*914像素，比例为16:10。<span className="form_blue_color">（支持上传多张）</span></p>
      <ImgUpload value={loopImg} limit={5} onChange={(e)=>{
        setLoopImg(e)
      }}/>
      <span className="error_info">商品轮播图不可为空</span>
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title" style={{marginTop:'16px'}}>图文详情</div>
    <TextEditor value={descption} onChange={(e)=>{
      setDescption(e)
    }} />
  </div>
  <div className="input_item">
    <div className="input_item_title" style={{marginTop:'16px'}}>购买须知</div>
    <TextEditor value={buyKnow} onChange={(e)=>{
      setBuyKnow(e)
    }} /> 
  </div>
</div>
};
export default PicDesc;