import './header.css';
import {Select} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
const {Option} = Select;
function ShopHeader(props){
  return <div className='shop_header'>
    <div className="left">
      <div className="shop_name">红丝婚礼商家中心</div>
      <Select className='change_shop'>
        <Option value="test">test</Option>
        <Option value="test1">test1</Option>
        <Option value="test2">test2</Option>
        <Option value="test3">test3</Option>
      </Select>
    </div>
    <div className="right">
      <div className="change_user">
        <span className="current_phone">当前登录用户手机号</span>
        <CaretDownOutlined />
      </div>
      <div className="add_user">新增商户</div>
    </div>
  </div>
};
export default ShopHeader;