import {  Pagination, Radio,Popconfirm } from 'antd';
import { Fragment, useEffect, useRef, useState } from 'react';
import request from '../../http';
import './index.css';
import PhotoItem from './photoItem';
import {message,randomStr} from '../../utils/tool';
function PhotoLibray(props){
  let inputFile = useRef();
  let [moreStatus,setMoreStatus] = useState(false);// 批量控制
  let [moreCount,setMoreCount] = useState(0);// 多选数量
  let [isAll,setAll] = useState();// 是否全选
  let [selectPhoto,setSelectPhoto] = useState([]);// 选中的图片
  let [total,setTotal] = useState(0);// 总条数
  let [page,setPage] = useState(1);// 当前页码
  let [limit,setLimit] = useState(24);// 页大小
  let [photoList,setPhotoList] = useState([]);// 图片库列表
  let [uploadedCount,setUploadedCount] = useState(0);// 已上传数量
  let [needUploadCount,setNeedUploadCount] = useState(0);// 需要上传的数量
  useEffect(()=>{
    getPhotoList()
  },[limit,page]);// 组件初始化
  useEffect(()=>{
    if(!moreStatus)return;
    setMoreCount(selectPhoto.length);
    setAll(selectPhoto.length === photoList.length)
  },[selectPhoto]);
  useEffect(()=>{
    if(!needUploadCount)return;// 没有需要上传的数量
    if(needUploadCount === uploadedCount){
      message({
        type:'success',
        message:'上传完成'
      });
      getPhotoList();
      // 上传前驱归零
      setUploadedCount(0);
      setNeedUploadCount(0)
    }
  },[uploadedCount]);
  // 
  function getPhotoList(){
    request.getPhotoList({
      mer_id:675,
      page,
      limit
    })
    .then(({data})=>{
      let {count,lists} = data;
      if(moreStatus && lists.length === 0){
        // 正在全选
        setMoreStatus(false)
      }
      setTotal(count)
      setPhotoList(lists);
    })
  };
  function uploadFile(){
    let {files} = inputFile.current;
    let fileList = Array.from(files).map(item=>{
      return {
        file:item,
        status:0,
        percent:0,
        name:item.name,
        id:randomStr()
      }
    });
    if(needUploadCount !== 0){
      // 还有需要上传的
      setNeedUploadCount(needUploadCount + fileList.length)
    }else{
      // 没有需要上传的
      setNeedUploadCount(fileList.length)
    };
    if(total == 0){
      setTotal(fileList.length)
    }
    let newList = [].concat(fileList,photoList)
    setPhotoList(newList)
    inputFile.current.value = '';
  };
  function changeSelect(id){
    if(selectPhoto.includes(id)){
      // 已存在
      selectPhoto = selectPhoto.filter(item=>item !== id);
    }else{
      // 不存在
      selectPhoto = selectPhoto.concat(id)
    };
    setSelectPhoto(selectPhoto);
  };
  function removePhtot(list=[]){
    if(needUploadCount !== 0){
      message({
        type:'error',
        message:'图片上传中，请稍后重试'
      });
      return;
    }
    let ids = list.join(',');
    request.removePhotoLibray({
      ids
    })
    .then(res=>{
      message({
        type:'success',
        message:res.msg
      })
      setSelectPhoto([]);
      getPhotoList();
    })
  };
  // 全选本页
  function selectorAll(){
    let result;
    if(isAll){
      // 已经全选
      result = [];
    }else{
      // 未全选
      result = photoList.map(item=>item.id)
    };
    setSelectPhoto(result)
  };
  function uploadCount(){
    setUploadedCount(++uploadedCount)
  }
  return <div className="photo_libray">
      <div className="libray_head content_block">
        <div className="libray_page_title">所有图片</div>
        <div>
          <div className="libray_btn_big add" onClick={()=>{
            inputFile.current.click();
          }}>
            <img className="libray_btn_icon" src={require('../../static/icon/libray_add.png')} />
            <span>上传图片</span>
          </div>
          <div className="libray_btn_big static" onClick={()=>{
            setMoreStatus(true)
          }}>
            <img className="libray_btn_icon" src={require('../../static/icon/libray_mange.png')} />
            <span>批量管理</span>
          </div>
        </div>
      </div>
      <div className="libray_info content_block">
        素材库内容会定期进行审查，违规内容将系统删除
      </div>
      {
        !moreStatus ? '' : (<div className="libray_ctrl content_block">
        <div className="all_info">
          <Radio checked={isAll} onClick={(e)=>{
            selectorAll()
          }}>{isAll?'取消全选':'全选本页'}</Radio>
          <div className="select_count">
            <span>已选择<i>{moreCount}</i>张</span>
          </div>
        </div>
        <div>
          <Popconfirm
            placement="topRight"
            title={`确认删除${moreCount}张图片素材吗？`}
            onConfirm={()=>{
              removePhtot(selectPhoto)
            }}
            okText="确认"
            cancelText="取消"
          >
            <div className="libray_btn_small cancel">删除</div>
          </Popconfirm>
          <div className="libray_btn_small conmit" onClick={()=>{
            setMoreStatus(false)
          }}>完成</div>
        </div>
      </div>)
      }
      {
        total > 0 ? (<Fragment>
          <div className="libray_list content_block">
            {
              photoList.map((item,index)=>{
                return <PhotoItem key={item.id} moreStatus={moreStatus} onLoad={uploadCount} onRemove={removePhtot} onChange={changeSelect} {...item} selectPhoto={selectPhoto} />
              })
            }
          </div>
          <div className="libray_pagination content_block">
            <Pagination 
              current={page}
              total={total}
              pageSize={limit}
              onChange={(page,pageSize)=>{
                setLimit(pageSize);
                setPage(page);
              }}
            />
          </div>
        </Fragment>) : (<div className="empty_info">
        <img className="empty_icon" src={require('../../static/icon/libray_empty.png')} />
        <p>暂无内容</p>
      </div>)
      }
      
      <input className="upload_dom" onChange={uploadFile} multiple type="file" accept="image/*" ref={inputFile} />
  </div>
};
export default PhotoLibray;