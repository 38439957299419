import {Radio} from 'antd';
import { useEffect, useRef, useState } from 'react';
import {setTableCellCenter,message} from '../../../utils/tool';
import {EditableTable} from '../../../components/index';
import api from '../../../http/api';
import request from '../../../http';
function SaleAttr(props){
  let {info,onChange,emptyType} = props;
  let [skuType,setSkuType] = useState('1');
  let [singleSku,setSingleSku] = useState([{}]);// 单规格数据
  let [moreSku,setMoreSku] = useState([{}]);// 多规格数据
  let [skuListValue,setSkuListValue] = useState([]);// 规格列表
  let [newSkuValue,setNewSkuValue] = useState('');
  let [skuName,setSkuName] = useState('');// 规格名称
  let [skuConfig,setSkuConfig] = useState([]);// 规格表格配置
  let [moreSkuValueList,setMoreSkuValueList] = useState([]);// 多规格列表数据
  let [pushSku,setPushSku] = useState(undefined);
  let [changeSkuValue,setChangeSkuValue] = useState(undefined);
  let fileInput = useRef();// 上传文件
  let [uploadIndex,setUploadIndex] = useState(undefined);
  let [removeEvent,setRemoveEvent] = useState(undefined)
  // 删除监听
  useEffect(()=>{
    if(typeof removeEvent === 'undefined')return;
    moreSku.splice(removeEvent,1);
    setMoreSku([...moreSku])
    setRemoveEvent(undefined);
  },[removeEvent])
  // 规格数据监听
  useEffect(()=>{
    let newValue = {
      goods_moneys:skuListValue,
      goods_sell_nature:skuListValue,
      goods_spec:skuType
    };
    onChange(newValue)
  },[skuListValue])
  // 基本数据
  // 多规格添加表格配置
  let skuBasicCol = [
    {
      title:'现价',
      dataIndex:'attrbute_money',
      editable:true
    },
    {
      title:'原价',
      dataIndex:'attrbute_price',
      editable:true
    },
    {
      title:'库存',
      dataIndex:'attrbute_store',
      editable:true
    }
  ];
  let moreSkuCtrlCol = [
    {
      title:'规格名称',
      dataIndex:'skuName',
      width:200,
      editable:true
    },
    {
      title:'规格值',
      dataIndex:'skuInfo',
      editable:true,
      slot:<span className="table_cell_edit" onClick={()=>{
        setPushSku(new Date().getTime())
      }}>确认添加</span>
    }
  ];
  // 单规格sku表格配置
  let singleSkuCol = [
    ...skuBasicCol
  ];
  // 多规格sku表格配置
  let moreSkuCol = [
    ...skuBasicCol,
    {
      title:'图片',
      ellipsis: true,
      width:120,
      render({attrbute_img},rowData,index){
        return <div className="cell_img_holder" onClick={()=>{
          setUploadIndex(index);
          fileInput.current.click()
        }} >
          {
            attrbute_img ? <img src={api.imgUrl + attrbute_img} /> : <div className="cel_upload_btn">上传图片</div>
          }
        </div>
      }
    },
    {
      title:'操作',
      render(data,rowData,index){
        return <span className="table_cell_edit" onClick={()=>{
          setRemoveEvent(index)
        }}>删除</span>
      }
    }
  ];
  function uploadImg(){
    let file = fileInput.current.files[0];
    let formData = new FormData();
    formData.append('file',file)
    request.uploadImg(formData)
    .then(({data})=>{
      // moreSku,setMoreSku
      // uploadIndex,setUploadIndex
      moreSku[uploadIndex].attrbute_img = data.data;
      setMoreSku([...moreSku]);
      setUploadIndex(undefined);
    });
  };
  // 规格类型产生变化
  useEffect(()=>{
    let skuConfigResult = [];
    // 处理多规格输入表格 
    if(skuType === '2'){
      setSkuListValue(moreSku)
    }else{
      setSkuListValue(singleSku)
    };
    // 处理sku输入表格
    setMoreSkuHandler(skuConfigResult);
  },[skuType]);
  // 修改表格数据
  useEffect(()=>{
    if(typeof changeSkuValue === 'undefined')return;
    let {dataIndex,key,value} = changeSkuValue;
    if(skuType === '1'){
      singleSku[dataIndex][key] = value[key];
      setSkuListValue([...singleSku])
    }else{
      moreSku[dataIndex][key] = value[key];
      setSkuListValue([...moreSku])
    };
  },[changeSkuValue])
  // 规格名称发生变化 -> 初始化skuListValue
  useEffect(()=>{
    setMoreSkuValueList([{skuName:skuName,skuInfo:newSkuValue}]);
  },[skuName,newSkuValue])
  // 回填数据产生变化
  useEffect(()=>{
    let {goods_spec,goods_sell_nature} = info;
    if(goods_spec === '1'){
      // 单规格
      setSingleSku(goods_sell_nature);
    }else if(goods_spec === '2'){
      // 多规格
      setSkuType(goods_spec);
      setSkuName(goods_sell_nature[0].attribute_title);
      setMoreSku(goods_sell_nature);
    };
    // 处理sku表格
    setMoreSkuHandler();
  },[info.goods_spec]);
  // 确认添加sku 按钮派发事件监听
  useEffect(()=>{
    if(typeof pushSku === 'undefined')return;
    let hasOldValue = moreSku.some(item=>item.attrbute_volume === newSkuValue);
    if(!newSkuValue || !skuName){
      message({
        message:`请输入规格名称！`,
        type:'error'
      })
      return;
    }
    if(hasOldValue){
      message({
        message:`规格：${newSkuValue}，已存在，请勿重复添加！`,
        type:'error'
      })
      return;  
    };
    // 校验规格名称是否变化
    if(moreSku[0]){
      let oldSkuName = moreSku[0].attribute_title;
      if(oldSkuName !== skuName){
        moreSku.forEach(item=>{
          item.attribute_title = skuName;
        });
        setMoreSkuHandler()
      };
    };
    moreSku.push({
      attrbute_volume: newSkuValue,
      attribute_title: skuName
    });
    setMoreSku(JSON.parse(JSON.stringify(moreSku)));
    setNewSkuValue('');
  },[pushSku])
  // 监听缓存sku数据变化 设置到当前主缓存渲染table
  useEffect(()=>{
    if(skuType === '2'){
      // 设置多规格
      setSkuListValue(JSON.parse(JSON.stringify(moreSku)))
    }else if(skuType === '1'){
      // 设置单规格
    }
  },[moreSku,singleSku]);
  // 组件方法
  // 设置多规格表格列配置x
  function setMoreSkuHandler(){
    let skuConfigResult = [];
    if(skuType === '2'){
      // 多规格
      if(skuName){
        // 存在规格名称
        skuConfigResult.push({
          title:skuName,
          ellipsis: true,
          editable:true,
          width:140,
          dataIndex:'attrbute_volume'
        });
      };
      skuConfigResult = skuConfigResult.concat(moreSkuCol);
    }else if(skuType === '1'){
      // 单规格
      skuConfigResult = skuConfigResult.concat(singleSkuCol);
    };
    setSkuConfig(setTableCellCenter(skuConfigResult))
  };
  // 切换sku类型
  function changeSkuType(e){
    setSkuType(e.target.value)
  };
  const Radios = [
    {
      label:'一口价',
      value:'1'
    },
    {
      label:'有多层规格',
      value:'2'
    }
  ];
  return <div className="input_list">
  <div className="input_item center">
    <div className="input_item_title must_item">是否有规格</div>
    <div className="input_item_value">
      <Radio.Group options={Radios} value={skuType} onChange={changeSkuType} />
    </div>
  </div>
  <input type="file" accept="image/*" ref={fileInput} onChange={uploadImg} style={{position:'absolute',top:'-9999px',left:'-9999px',opacity:'0'}} />
  {
    skuType === '2' ? (<div className="sku_add">
      <EditableTable 
        columns={moreSkuCtrlCol} 
        dataSource={moreSkuValueList}
        onChange={(rowIndex,key,value)=>{
          if(key === 'skuName'){
            setSkuName(value[key]);
          }else if(key === 'skuInfo'){
            setNewSkuValue(value[key]);
          };
        }}
      ></EditableTable>
    </div>) : ''
  }
  <div className="input_item">
    <div className="input_item_title must_item" style={{marginTop:'10px'}}>价格库存</div>
    <div className={`${skuType === '1' ? 'input_item_value' : 'input_more_value'} ${emptyType === 'sku_list' ? 'error' : ''}`}>
      <EditableTable 
        columns={skuConfig} 
        bordered
        dataSource={skuListValue}
        onChange={(dataIndex,key,value)=>{
          setChangeSkuValue({dataIndex,key,value});
        }}
      ></EditableTable>
      <span className="error_info">请输入完整规格信息</span>
    </div>
  </div>
  </div>
};
export default SaleAttr;