import { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router';
function Slide(props){
  let {path,title,onHeight} = props;
  let menu = useRef();
  let {pathname} = useLocation();
  let [isActive,setActive] = useState(false);
  useEffect(()=>{
    onHeight(menu.current.clientHeight);
  },[]);
  useEffect(()=>{
    setActive(pathname.includes(path))
  },[pathname])
  return <li ref={menu} className={`${isActive ? 'active': ''}`}>
    <Link to={path} className="slide_link">{title}</Link>
  </li>
};
export default Slide;