/**
 * @params [menuItem]
 * @params menuItem:{
 *  title:一级菜单名称
 *  pageKey: 一级菜单唯一key (用户鉴权需要)
 *  path: 一级菜单默认跳转路由
 *  opening: 一级菜单固定是否展示开关（早期鉴权模拟）
 *  icon: 一级菜单图片 需要require引入base64
 *  children:[{  二级菜单数组
 *    title: 二级菜单名称
 *    path: 二级菜单path地址 （跳转/激活状态需要）
 *  }]
 * }
 */
export default [
  {
    title:'商家管理',
    pageKey:'shopMange',
    path:'renovation',
    opening:true,
    icon:require('../static/icon/shop.png'),
    activeIcon:require('../static/icon/shop_active.png'),
    children:[
      {
        title:'店铺装修',
        path:'renovation'
      },
      {
        title:'商家信息管理',
        path:'infoMange'
      }
    ]
  },
  {
    title:'商品/案例管理',
    pageKey:'goodsMange',
    path:'goodsMange',
    opening:true,
    icon:require('../static/icon/goods.png'),
    activeIcon:require('../static/icon/goods_active.png'),
    children:[
      {
        title:'商品管理',
        path:'goodsMange'
      },
      {
        title:'案例管理',
        path:'caseMange'
      },
      {
        title:'图片素材库',
        path:'photoLibray'
      }
    ]
  },
];